:global(.BrainhubCarouselItem) {
  /*display: flex;*/
  justify-content: center;
  align-items: center;
  position: relative
}

:global(.BrainhubCarouselItem.BrainhubCarouselItem--clickable) {
  cursor: pointer
}

:global(.BrainhubCarouselItem .debug-number) {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  font-size: 2em;
  text-shadow: 0px 0px 9px white
}

:global(.BrainhubCarousel) {
  /*overflow: hidden;*/
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

:global(.BrainhubCarousel.BrainhubCarousel--isRTL) {
  direction: rtl
}

:global(.BrainhubCarousel.BrainhubCarousel--isRTL .BrainhubCarousel__trackContainer .BrainhubCarousel__track) {
  direction: rtl
}

:global(.BrainhubCarousel .BrainhubCarousel__trackContainer) {
  overflow: hidden
}

:global(.BrainhubCarousel .BrainhubCarousel__trackContainer .BrainhubCarousel__track) {
  display: flex;
  overflow: hidden;
  list-style: none;
  margin: 0;
  padding: 0
}

:global(.BrainhubCarousel .BrainhubCarousel__trackContainer .BrainhubCarousel__track.BrainhubCarousel__track--transition) {
  transition: transform
}

:global(.BrainhubCarousel__arrows) {
  cursor: pointer
}

:global(.BrainhubCarousel__dots) {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 0
}

:global(.BrainhubCarousel__dots.BrainhubCarousel__dots--isRTL) {
  direction: rtl
}

:global(.BrainhubCarousel__dots .BrainhubCarousel__dot) {
  outline: 0;
  padding: 10px;
  border: none;
  opacity: 0.5;
  cursor: pointer;
  -webkit-appearance: none
}

:global(.BrainhubCarousel__dots .BrainhubCarousel__dot.BrainhubCarousel__dot--selected) {
  opacity: 1 !important
}

:global(.BrainhubCarousel__dots .BrainhubCarousel__dot:hover) {
  opacity: 1
}

:global(.BrainhubCarousel__dots .BrainhubCarousel__dot:before) {
  content: '';
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  padding: 0;
  border: none;
  background: #000
}

:global(.BrainhubCarousel__dots .BrainhubCarousel__thumbnail) {
  outline: 0;
  padding: 10px;
  border: none;
  opacity: 0.5;
  cursor: pointer
}

:global(.BrainhubCarousel__dots .BrainhubCarousel__thumbnail.BrainhubCarousel__thumbnail--selected) {
  opacity: 1 !important
}

:global(.BrainhubCarousel__dots .BrainhubCarousel__thumbnail:hover) {
  opacity: 1
}

:global(.BrainhubCarousel__thumbnail[type=button]) {
  -webkit-appearance: none
}

:global(.BrainhubCarousel--isRTL + .BrainhubCarousel__dots) {
  direction: rtl
}

:global(.BrainhubCarousel__arrows) {
  position: absolute;
  padding: 21px;
  top: -60px;
  right: 0;
  border: none;
  overflow: hidden;
  outline: 0;
  font-size: 0;
  line-height: 0;
  z-index: 100;
}
:global(.BrainhubCarousel__arrowLeft){
  right: 50px;
}
:global(.BrainhubCarousel__arrowRight){
  right: 0;
}
:global(.BrainhubCarousel__customArrows){
  position: absolute;
  padding: 0px;
  top: -30px;
  right: 0;
  border: none;
  outline: 0;
  font-size: 0;
  line-height: 0;

  z-index: 100;
  width: 1px;
}
:global(.BrainhubCarousel__customArrows button){
  padding: 24px 21px;
  background: transparent;
  min-height: 0;
  -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.4);
  -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.4);
  box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.4);
}
:global(.BrainhubCarousel__customArrows button:hover){
  -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.4);
  -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.4);
  box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.4);
}

:global(.BrainhubCarousel__arrows span) {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  border-style: solid;
  border-color: #000;
  border-width: 3px 3px 0 0;
  padding: 5px;
  transition: 0.3s;
  font-size: 0
}


:global(.BrainhubCarousel__arrows:hover span) {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  border-style: solid;
  border-color: #fff;
  border-width: 3px 3px 0 0;
  padding: 5px;
  transition: 0.3s;
  font-size: 0
}

:global(.BrainhubCarousel__arrows:hover:enabled span) {
  border-color: #fff;
  margin: 0
}

:global(.BrainhubCarousel__arrows:disabled) {
  background-color: #ccc
}

:global(.BrainhubCarousel__arrowLeft span) {
  transform: translate(-50%, -50%) rotate(-135deg);
  margin-left: 2.45px
}

:global(.BrainhubCarousel__arrowRight span) {
  transform: translate(-50%, -50%) rotate(45deg);
  margin-left: -2.45px
}

:global(.BrainhubCarousel--isRTL .BrainhubCarousel__arrowLeft span) {
  transform: translate(-50%, -50%) rotate(45deg);
  margin-left: -2.45px
}

:global(.BrainhubCarousel--isRTL .BrainhubCarousel__custom-arrowLeft span) {
  transform: rotate(180deg)
}

:global(.BrainhubCarousel--isRTL .BrainhubCarousel__arrowRight span) {
  transform: translate(-50%, -50%) rotate(-135deg);
  margin-left: 2.45px
}

:global(.BrainhubCarousel--isRTL .BrainhubCarousel__custom-arrowRight span) {
  transform: rotate(-180deg)
}

:global(.BrainhubCarousel--isRTL .BrainhubCarousel__arrows:hover span) {
  margin: 0
}

:global(.BrainhubCarousel__arrow--disable) {
  pointer-events: none
}

/*# sourceMappingURL=style.css.map*/
@media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : landscape) {

}

@media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : portrait) {
  .BrainhubCarousel__customArrows button{

  }
}
@media only screen and (min-width : 375px) and (max-width : 812px) and (-webkit-device-pixel-ratio : 3) {
  :global(.BrainhubCarousel__customArrows){
    top: 30px;
  }
  :global(.BrainhubCarousel__customArrows button){
    padding: 15px;
  }
  :global(.BrainhubCarousel__arrowLeft){right: 40px;}
}
@media only screen and (min-width : 375px) and (max-width : 667px) {
  :global(.BrainhubCarousel__customArrows){
    top: 30px;
  }
  :global(.BrainhubCarousel__customArrows button){
    padding: 15px;
  }
  :global(.BrainhubCarousel__arrowLeft){right: 40px;}
}

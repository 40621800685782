.base {
  width: fit-content;
  padding: 10px;
  display: flex;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  color: rgb(93, 93, 93);
}

.input {
  display: none;
}

.label {
  padding: 10px 5px 10px 45px;
  margin-left: -45px;
  z-index: 10;
}

.imgDiv {
  height: 25px;
  width: 25px;
  border: 2px solid rgb(93, 93, 93);
  border-radius: 3px;
  margin-right: 10px;
  padding: 2.5px;
  transition: 80ms ease-out;
}

.img {
  width: 100%;
  height: 100%;
  display: none;
  /* filter: invert() */
}

.input:checked + .imgDiv {
  background-color: #007373;
  box-shadow: 4px 14px 28px -9px rgba(168, 168, 168);
  border: 2px solid #007373;
}

.input:checked + .imgDiv .img {
  display: inline;
}

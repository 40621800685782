.base {
  height: 50px;
  width: 195px;
  border-radius: 10px;
  transition: all 200ms;
  border: 1px solid #007373;
  background-color: #ffffff;
  position: fixed;
  opacity: 0.9;
  /*top: 0px;*/
  right: 10px;
  z-index: 200;
  overflow-y: scroll;
  color: #212121;
  font-family: 'Roboto', sans-serif;
  box-shadow: 0 0 20px 0 #cacaca;
}

.base::-webkit-scrollbar {
  width: 0;
  background: transparent; /* make scrollbar transparent */
}

.toggleButton {
  border-radius: 7px;
  background-color: #5d5d5d;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 20;
}

.notOpened {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
  transition: all 500ms;
  position: relative;
}

.statusBar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #007373;
  transition: all 400ms;
}

.statusText {
  z-index: 20;
}

.opened {
  display: flex;
  align-items: center;
  transition: all 500ms;
  flex-direction: column;
  overflow: hidden;
  padding: 10px;
}

.opened_heading {
  width: 100%;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 500;
}

.stepRow {
  display: flex;
  align-items: center;
}

.actionButton {
  color: #3b5999;
  cursor: pointer;
}

.taskIcon {
  margin: 20px;
}

/*@media screen and (max-width: 1855px) {*/
/*  .base {*/
/*    top: 90px;*/
/*  }*/
/*}*/

.base {
  position: relative;
  font-family: 'Roboto', sans-serif;
  color: #d4ac75;
  background: #4a4a4a;
  padding: 5px 15px 5px 40px;
  border-radius: 25px;
  display: inline-block;
  font-size: 16px;
  vertical-align: middle;
  margin-left: 20px;
  float: right;
  line-height: 26px;
}
.base::before {
  background: #d4ac75;
  width: 15px;
  height: 15px;
  border-radius: 10px;
  content: ' ';
  display: block;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 768px) {
  .base {
    padding: 0px 15px 0px 40px;
    line-height: 35px;
    float: left;
    margin-left: 30px;
  }
}

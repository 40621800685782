.outerbase {
  width: 100%;
  font-family: 'Roboto', sans-serif;
  color: rgb(73, 73, 73);
  /* margin: 100px 0; */
  height: 1000px;
  /* background-image: url('data:image/svg+xml;utf8,<svg width="7" height="10" viewBox="0 0 7 10" xmlns="http://www.w3.org/2000/svg"><path d="M5.667 1c.094 0 .188.04.254.118.12.14.102.35-.038.47L1.85 5l4.032 3.412c.14.12.158.33.04.47-.12.14-.33.158-.47.04L1.117 5.254C1.043 5.19 1 5.098 1 5s.043-.19.118-.255L5.45 1.08c.064-.054.14-.08.217-.08" stroke="%23FFF" fill="%23FFF" fill-rule="evenodd"/></svg>'); */
  background-image: url(../../assets/signup_image.png);
  background-size: cover;
  padding-top: 5%;
}

.base {
  display: flex;
  flex-direction: row;
  margin: 0 15% 0 15%;
  font-family: 'Roboto', sans-serif;
  color: rgb(61, 61, 61);
  border-radius: 10px;
  color: rgb(73, 73, 73);
  height: 725px;
  box-shadow: 4px 14px 28px 4px rgba(168, 168, 168, 0.75);
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.leftDiv {
  width: 50%;
  padding: 20px;
  background-color: white;
}

.rightDiv {
  width: 50%;
  padding: 20px 65px;
  font-size: 52px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  text-align: right;
}

.rightDiv p {
  margin: 10px 0;
}

.rightDiv > div p:nth-child(1) {
  color: white;
}

.rightDiv > div p:nth-child(2) {
  color: #4c00ba;
}

.rightDiv > div p:nth-child(3) {
  color: #4c00ba;
}

.row > div {
  border-bottom: burlywood solid 1px;
}

.signinBlock {
  display: flex;
  justify-content: center;
}

.signinBlock p:nth-child(2) {
  color: #5d5d5d;
  font-weight: 600;
}

.codeInput {
  /* code input style */
}

.codeInputOuterDiv > div > div > input:nth-child(2) {
  border: none;
  border-bottom: solid 2px #ac8342;
}

.codeInputOuterDiv > div > div > input:nth-child(3) {
  border: none;
  border-bottom: solid 2px #ac8342;
}

.codeInputOuterDiv > div > div > input:last-child {
  border: none;
  border-bottom: solid 2px #ac8342;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.codeInputOuterDiv > div > div > input:first-child {
  border: none;
  border-bottom: solid 2px #ac8342;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.codeInputOuterDiv > div {
  width: 100% !important;
}

.codeInputOuterDiv > div > div > input {
  min-width: 60px;
  border: none;
  border-bottom: solid 2px #ac8342;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  margin: 3%;
}

.resendButton > a {
  border: solid 1px #ac8342;
  border-radius: 3px;
}

.rowBottomDiv {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 150px;
}

.cardPadding {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.cardPadding > div:first-child {
  padding-right: 10px;
}

.justifySpaceRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.signUpContent > div > div {
  width: 100%;
  margin-bottom: 20px;
}

.signUpContent > p > span:nth-child(1) {
  font-family: 'Roboto', sans-serif;
  font-size: 25px;
  color: #ac8342;
  font-weight: 500;
}

.signUpContent > p > span:nth-child(2) {
  font-family: 'Roboto', sans-serif;
  font-size: 25px;
  color: #534d41;
}

.button {
  display: flex;
  justify-content: flex-end;
}

.button > div:nth-child(1) {
  width: 45%;
}

.errorMessage {
  font-family: 'Roboto', sans-serif;
  font-size: 10px;
  color: rgb(148, 53, 53);
}

.signup_method_item {
  padding: 10px 0;
}
.signup_method_item a {
  padding: 15px;
  display: block;
}

.verificationEmail {
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  text-align: center;
}

.verificationTitle {
  font-size: 30px;
  font-weight: 600;
}

@media screen and (max-width: 780px) {
  /* tablet */
}

@media screen and (max-width: 480px) {
  /* mobile */
  .logo > img {
    height: 40px;
    width: 120px;
  }

  .verificationEmail {
    height: 370px;
  }

  .rightDiv {
    padding: 110px 30px;
    font-size: 24px;
  }
}

.card {
  position: relative;
  min-width: 200px;
  height: 170px;
  margin: 0;
  box-shadow: 4px 14px 28px -9px rgba(168, 168, 168, 0.75);

  overflow: hidden;
}

.card:hover .overlay {
  opacity: 0.3;
}

.overlay {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAH0lEQVQYV2NkQAX/GZH4/xkYGBhhAmAOSBJEwDkgAQCCrgQEjpMcPgAAAABJRU5ErkJggg==')
    repeat;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  z-index: 0;
  transition: all 300ms ease-in-out;
}

.img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.text {
  font-family: 'Roboto', sans-serif;
  color: white;
  font-size: 35px;
  margin: 0;
  position: absolute;
  right: 30px;
  bottom: 7px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .card {
    width: auto;
  }
}

@media only screen and (max-device-width: 768px) {
  .card {
    width: auto;
  }
  .text {
    font-size: 20px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) {
  .card {
    width: auto;
  }
}

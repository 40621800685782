.wrapper{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.video{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.video > div{
  display: flex;
  width: 100%;
}

.input{
  border-radius: 9px;
  border: 1px solid rgba(144, 144, 144, 0.705);;
  min-height: 60px;
  width: 600px;
  font-size: 20px;
  color: rgb(80, 80, 80);
  margin: 0 0 20px 0;
}

.inputContainer{
  position: relative;
}

.remove{
  display: none;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  position: absolute;
  background: rgb(173, 57, 57);
  top: 0;
  right: 0;
  margin: -12px -12px 0 0;
  cursor: pointer;
  color: white;
  justify-content: center;
  align-items: center;
}

.inputContainer:hover .remove{
  display: flex;
}

.addButton{
  margin-left: 30px;
  display: flex;
  gap: 10px;
  margin-top: 23px;
  color: #007373;
  cursor: pointer;
}

.imageView{
  display: flex;
  flex-wrap: wrap;
}

.imageView > div > img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 9px;
  background-color: rgba(236, 236, 236, 0.486)
}

.imageView > div{
  width: 200px;
  height: 185px;
  margin: 0 20px 20px 0;
  border-radius: 9px;
  position: relative;
}

.removePic{
  display: none;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  position: absolute;
  background: rgb(173, 57, 57);
  top: 0;
  right: 0;
  margin: -12px -12px 0 0;
  cursor: pointer;
  color: white;
  justify-content: center;
  align-items: center;
  z-index: 6;
}

.imageView > div:hover .removePic{
  display: flex;
}

.imageView > div:last-child{
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px dashed rgba(184, 184, 184, 0.472);
}

.imageView > div:last-child > input{
  display: none;
}

.imagePickerIcon{
  font-size: 80px;
  cursor: pointer;
}

.error{
  color: rgb(255 91 91);
  font-size: 20px;
  padding: 20px;
  background-color: #f1f1f11a;
  border-radius: 10px;
  width: -moz-fit-content;
  width: fit-content;
  box-shadow: 1px 1px 15px 2px #e6e6e6;
  font-style: italic;
}

.next{
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  margin: 25px;
  margin-right: 0;
}

.next p{
  margin-right: 20px;
  font-size: 26px;
  color: rgb(66, 66, 66)
}

.button{
  height: 45px;
  width: 140px;
  background-color: #007373;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  margin-top: 20px;
  align-self: flex-end;
}

@media only screen and (max-width: 600px) {
  .wrapper {
    padding: 20px;
  }

  .input{
    width: 100%;
  }

  .video > div{
    display: flex;
    flex-direction: column;
  }

  .addButton{
    align-self: flex-end
  }

  .imageView{
    align-items: center;
  }

  .imageView > div{
    margin: 20px 0 0 0;
  }
}

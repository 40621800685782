.chatsWrapper {
}

.base {
  position: fixed;
  bottom: 0;
  /*right: 20px;*/

  z-index: 500;
  margin-left: 20px;
  height: 400px;
  width: 300px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  overflow: hidden;
  transition: 200ms ease-out;
  box-shadow: 0 0 20px 0 #717171;
  background-color: white;
}

.nav {
  width: 100%;
  height: 30px;
  background-color: #3f3f3f;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.messageBase {
  display: flex;
  flex-direction: column;
}

.readIcon {
  color: #6ac0d4;
  font-size: 10px;
  padding-right: 5px;
}

.items {
  height: 340px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  align-items: center;
}

.item {
  margin: 5px 10px 0 10px;
}

.input {
  height: 30px;
  width: 100%;
  display: flex;
  border: 3px solid #007373;
  border-radius: 3px;
}

.inputField {
  height: 100%;
  width: 240px;
  border: none;
}

.inputField:focus {
  outline: none;
}

.sendButton {
  width: 60px;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #007373;
}

.onlineStatusIcon {
  font-size: 10px;
  margin: 10px;
}

.chatName {
  width: 230px;
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.actionIcon {
  padding: 10px;
}

.navActions {
  display: flex;
  align-items: center;
}

.chatsBase {
  position: fixed;
  display: flex;
  bottom: 0;
}

.minimizedBase {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30px;
}

.minimizedBubbleBase {
  /*background-color: #00acee;*/
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
  cursor: pointer;
}

.bubbleOnlineStatus {
  position: absolute;
  top: 0;
  right: 0;
}

.bubbleImg {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.mobileChatboxBase {
  height: 100%;
  width: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: white;
  z-index: 10;
}

.mobileInput {
  position: fixed;
  bottom: 0;
  height: 40px;
  width: 100%;
  display: flex;
  border: 3px solid #007373;
  border-radius: 3px;
}

.inputFieldMobile {
  width: 80%;
}

.sendButtonMobile {
  width: 20%;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #007373;
}

.messageItemsMobile {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  align-items: center;
}

.itemWrapper {
  display: flex;
  flex-direction: column;
  border: 2px solid #007373;
  margin-top: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.tooltip {
  background: #333333 !important;
  color: #a1a1a1 !important;
}

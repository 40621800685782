.base {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
}

.base p {
  margin: 0;
  padding: 0;
}

.filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.filter {
  padding: 10px;
  background-color: #007373;
  margin: 10px;
  border-radius: 7px;
  color: white;
  position: relative;
}

.filter > p:nth-child(2) {
  font-size: smaller;
  filter: brightness(90%);
}

.filter > p > span {
  font-size: smaller;
  filter: brightness(90%);
}

.clearFilters {
  cursor: pointer;
}

.removeFilter {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.base {
  align-items: center;
  color: #404040;
  font-family: 'Roboto', sans-serif;
}
.delivery_list_title {
  font-size: 16px;
  margin-bottom: 20px;
}
.delivery_list {
  font-size: 14px;
}
.delivery_list::after {
  display: block;
  clear: both;
  content: ' ';
}
.delivery_item {
  color: #404040;
  margin: 5px 10px 5px 0;
  display: block;
}
.last_delivery {
  display: inline-block;
}
.delivery_more {
  color: #5d5d5d;
  font-style: italic;
}

.wrapper{
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  background: #e7e7e7;
  padding: 30px 70px;
}

.space {
  margin: 1.2vw;
}

.trusted_container{
  display: flex;
  flex-wrap: wrap;
  padding:30px 0;
  margin-left: -35px;
  margin-right: -35px;
}
.trusted_item{
  flex: 25%;
  max-width: 25%;
  padding: 10px 35px;
  text-align: center;
}

.trusted_item{
  flex: 25%;
  max-width: 25%;
  padding: 10px 35px;
  text-align: center;
}
.trusted_item img{
  height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.trustedImages{
  padding: 20px;
}

.slick_slider_base > div > div > div{
  padding: 0 35px;
}

.slick_slider_base > div > div {
  display: flex;
  align-items: center;
}

@media only screen and (max-width : 768px){
  /* .wrapper{padding: 25px 40px 0;} */
  .trusted_container{
    margin-left: -25px;
    margin-right: -25px;
    padding: 0 0 30px;
  }
  .trusted_item{padding: 10px 15px; flex: 50%; max-width: 50%;}
}

@media only screen and (min-width : 375px) and (max-width : 812px) and (-webkit-device-pixel-ratio : 3){

}
@media only screen and (min-width : 375px) and (max-width : 667px) {
}

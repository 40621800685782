.discover_item {
  font-family: 'Roboto', sans-serif;
  text-align: center;
}
.discover_item a {
  color: #000000;
  text-decoration: none;
}
.img {
  filter: hue-rotate(125deg);
  width: 80px;
  height: 80px;
  object-fit: contain;
  margin: 0 auto 15px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
}

@media only screen and (max-device-width: 768px) {
  .wrapper {
    padding: 0px 40px;
  }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) {
  /*.discover_item{
    width: 33.333333%;
    margin-bottom: 20px;
  }*/
  .discover_item a {
    font-size: 13px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
  /*.discover_item{
    width: 33.333333%;
    margin-bottom: 20px;
  }*/
  .discover_item a {
    font-size: 13px;
  }
}

.base {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-family: 'Roboto', sans-serif;
  background-color: white;
  border-radius: 10px;
  margin: 50px 50px;
}
.image {
  display: flex;
  align-items: baseline;
}

.image .bigCircle {
  background-color: #5d5d5d;
  width: 180px;
  height: 180px;
  border-radius: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
/*.image .bigCircle:before{*/
/*  position: absolute;*/
/*  top: -15px;*/
/*  left: -5px;*/
/*  content: ' ';*/
/*  display: block;*/
/*  background-color: #5d5d5d;*/
/*  width: 90px;*/
/*  height: 90px;*/
/*  border-radius: 100%;*/
/*}*/

.image img {
  height: 150px;
  width: 150px;
  object-fit: cover;
  border-radius: 100%;
  z-index: 10;
}

.name {
  margin-top: -50px;
  color: white;
  white-space: nowrap;
}

.name .title {
  color: #007373;
  margin: 0;
  font-size: 30px;
  font-weight: 700;
}

.name .job_1 {
  color: rgb(53, 53, 53);
  margin: 0;
  font-size: 15px;
  margin-top: -10px;
}

.name .job_2 {
  color: rgb(53, 53, 53);
  margin: 0;
  font-size: 15px;
  margin-top: -5px;
}

.quote {
  position: relative;
  padding: 30px 60px;
  color: rgb(53, 53, 53);
  font-size: 20px;
}
.quoteContent {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /*-webkit-line-clamp: 4;*/
  -webkit-box-orient: vertical;
}

.quote img:first-child {
  position: absolute;
  width: 40px;
  top: 0;
  left: 10px;
  margin: 0;
  transform: rotate(180deg);
}

.quote img:last-child {
  position: absolute;
  width: 40px;
  bottom: 0;
  right: 10px;
  margin: 0;
}

@media only screen and (max-width: 1100px) {
  .base {
    margin: 30px 30px;
  }
  .image .bigCircle {
    width: 150px;
    height: 150px;
  }
  .image img {
    width: 130px;
    height: 130px;
  }

  .name .title {
    font-size: 20px;
  }
  .name .job_1 {
    font-size: 14px;
  }
  .name .job_2 {
    font-size: 14px;
  }
  .quote {
    font-size: 14px;
    padding: 20px 20px;
  }

  .quote img:first-child {
    width: 20px;
    left: 0;
  }
  .quote img:last-child {
    width: 20px;
    right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .image .bigCircle {
    width: 120px;
    height: 120px;
  }
  .image img {
    width: 100px;
    height: 100px;
  }
  .name .title {
    font-size: 16px;
  }
  .name .job_1 {
    font-size: 12px;
  }
  .name .job_2 {
    font-size: 12px;
  }
}

@media only screen and (max-width: 768px) {
  .base {
    flex-direction: column;
  }
  .image {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .image .bigCircle {
    width: 120px;
    height: 120px;
  }
  .image .bigCircle:before {
    top: -15px;
    left: -0px;
    width: 60px;
    height: 60px;
  }
  .image img {
    width: 100px;
    height: 100px;
  }
  .name {
    margin: 0;
  }
  .quoteContent {
    text-align: center;
  }
}
@media only screen and (min-width: 375px) and (max-width: 812px) and (-webkit-device-pixel-ratio: 3) {
}
@media only screen and (min-width: 375px) and (max-width: 667px) {
}

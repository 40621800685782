.base {
  min-height: 390px;
  background-color: white;
  border-radius: 10px;
  color: #333333;
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 4px 14px 28px -9px rgba(168, 168, 168, 0.75);
  margin: 0 10px;
  width: 33.3333%;
}

.title {
  margin: 0;
  padding: 2vw;
  font-size: 20px;
}

.img {
  height: 80px;
  width: 80px;
  margin: 20px 0;
  object-fit: cover;
  filter: grayscale(100%);
}

.desc {
  margin: 0;
  padding: 30px;
  text-align: center;
  font-size: 15px;
}

.dark {
  background-color: #333333;
  color: #d9d9d9 !important;
}
.dark p {
  color: #d9d9d9 !important;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
}

@media only screen and (max-width: 768px) {
  .base {
    padding: 20px;
    min-height: 100px;
    width: 100%;
    margin: 10px 0;
  }
  .desc {
    padding: 0 10px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) {
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
}

.base {
  width: 100%;
  height: 140px;
  position: relative;
  font-family: 'Roboto', sans-serif;
  color: white;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
  /* box-shadow: 4px 14px 28px -9px rgba(168, 168, 168, 0.75); */
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}

.title {
  margin: 0 20px 8px 0;
  font-size: 25px;
  font-weight: 600;
  z-index: 1;
  text-shadow: -1px -1px 2px rgba(0, 0, 0, 0.8);
}

.base{
  width: 68px;
  height: 68px;
  background-color: #007373;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 4px 14px 28px -9px rgba(165, 115, 58, 0.75);
  cursor: pointer;
}

.icon{
  color: white;
  font-size: 30px
}

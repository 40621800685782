.wrapper {
  display: flex;
  flex-direction: column;
  /*margin: 20px;*/
  min-height: 600px;
}

.add {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 20px 0px 0;
}

.add > div > p {
  color: #007373;
  font-size: 15px;
  height: fit-content;
  margin: 0;
  margin-right: 20px;
}

.title {
  margin: 0px 0px 20px 10px;
  align-self: flex-start;
}

.hintIcon {
  color: #5d5d5d;
}

.gotoIcon {
  color: #007373;
  font-size: 30px;
  margin-right: 25px;
  cursor: pointer;
}

.tabSelected {
  background-color: transparent;
  border-bottom: 6px solid #007373 !important;
  color: #007373 !important;
}

.tab {
  border: none;
  color: rgb(53, 53, 53);
  font-size: 20px;
  margin-right: 30px;
  font-weight: 600;
  user-select: none;
  scroll-behavior: none;
}

.tablist {
  border: none;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
}

.listings {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin: 20px 0 0 0;
}

.line {
  width: 100%;
  margin-top: 10px;
  height: 2px;
  background-color: rgba(151, 151, 151, 0.507);
}

.button {
  height: 45px;
  width: 140px;
  background-color: #007373;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .listings {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    justify-content: center;
  }

  .listings > div {
    margin: 20px 20px 0 20px;
  }

}

@media only screen and (min-width: 1024px) and (max-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
  .wrapper {
    min-height: 790px;
  }
}

.base {
  position: relative;
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  display: none;
}
.base.base_premium {
}
.rate_type {
  border-top-left-radius: 15px;
  font-weight: 600;
  background: #333;
  display: inline-block;
  padding: 10px 15px;
  position: absolute;
  top: -38px;
  right: 0;
}
.container {
  background: #fff;
  color: #333;
  border-top-left-radius: 15px;
  padding: 20px 0 20px 20px;
  position: relative;

  -webkit-box-shadow: -2px 0px 38px 25px rgba(0, 0, 0, 0.18);
  -moz-box-shadow: -2px 0px 38px 25px rgba(0, 0, 0, 0.18);
  box-shadow: -2px 0px 38px 25px rgba(0, 0, 0, 0.18);
}
.base_premium .container {
  background: #333;
  color: #fff;
}
.premium_badge_right {
  text-align: right;
}
.premium_badge {
  position: relative;
  font-family: 'Roboto', sans-serif;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  color: #d4ac75;
  background: #4a4a4a;
  padding: 5px 15px 5px 40px;
  display: inline-block;
  font-size: 16px;
  vertical-align: middle;
  margin-left: 20px;
  float: right;
}
.premium_badge::before {
  background: #d4ac75;
  width: 15px;
  height: 15px;
  border-radius: 10px;
  content: ' ';
  display: block;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.tag_line {
  font-size: 14px;
}
.price_rating {
  padding: 10px 0;
}
.price_rating small {
  font-size: 16px;
  font-weight: 600;
  vertical-align: top;
}
.price_rating label {
  font-size: 28px;
  font-weight: 600;
}
.price_rating label span {
  font-size: 12px;
  font-weight: 300;
}
.price_rating label span::before {
  content: '/';
}
.bio_title {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.bio_title:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}
.bio_title h1 {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  display: inline-block;
  vertical-align: middle;
  line-height: 28px;
  flex: 1;
}
.bio {
  font-weight: bold;
  line-height: 28px;
}
.profileContactContainer {
  margin: 30px 0;
}
.book_now {
  background: #007373;
  color: #fff;
  border-top-left-radius: 50px;
  font-weight: 600;
  padding: 10px 30px 10px 40px;
  display: block;
}
.job {
  font-size: 12px;
  font-weight: 600;
}
.location {
  font-size: 12px;
  font-weight: 300;
}

.profile_avatar {
  object-fit: cover;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin: 15px 10px;
}
.ratingBlock {
  display: inline-block;
  float: left;
  line-height: 36px;
  font-family: 'font-awesome';
}
.starDark {
  color: #fff;
}
.starLight {
  color: #bbb;
}

.ratingLabel {
  font-size: 16px;
  font-weight: bold;
  line-height: 36px;
  margin-left: 15px;
}
.ratingLabel span {
  font-weight: 300;
}
.bottom_container {
  background: #333;
  color: #fff;
  position: relative;

  -webkit-box-shadow: -2px 0px 38px 25px rgba(0, 0, 0, 0.18);
  -moz-box-shadow: -2px 0px 38px 25px rgba(0, 0, 0, 0.18);
  box-shadow: -2px 0px 38px 25px rgba(0, 0, 0, 0.18);

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.base_premium .bottom_container {
  background: #333;
  color: #fff;
}
.bottom_container h2 {
  margin: 0;
}
.cc_icon {
  text-align: right;
  padding: 20px 0;
}
.socialIcons {
  padding-top: 3px;
}
.socialIcons * {
  color: #fff;
  margin: 0 20px 10px 0;
  font-size: 26px;
  cursor: pointer;
}
.contact_bottom {
  height: 55px;
}
.free_call {
  background: #007373;
  color: #fff;
  border-top-right-radius: 50px;
  font-weight: 600;
  padding: 15px 40px;
  position: absolute;
  bottom: 0;
  left: 0;
}
.contact_now {
  background: #007373;
  color: #fff;
  border-top-left-radius: 50px;
  font-weight: 600;
  padding: 15px 40px;
  position: absolute;
  bottom: 0;
  right: 0;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
}

@media only screen and (max-width: 768px) {
  .base {
    display: block;
  }
}

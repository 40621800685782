.wrapper {
  font-family: 'Roboto', sans-serif;
  padding: 40px;
}

.scroll {
  overflow-y: scroll;
  max-height: 60vh;
  /* box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.3); */
}

.scroll::-webkit-scrollbar {
  width: 12px;
}

.scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px #007373;
}

.title {
  font-size: 25px;
  margin: 0 0 10px;
}

.block1 {
  display: flex;
  justify-content: space-between;
}

.block1 div {
  display: flex;
}

.block2 {
  display: flex;
}

.price {
  width: 50%;
}

.priceRange {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  position: relative;
}

.priceRange .rangeLabel {
  color: #5d5d5d;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.priceRange .rangeLabel p {
  margin: 0;
  margin-right: 10px;
  font-size: 13px;
}

.priceRange .rangeLabel2 {
  color: #007373;
  margin-left: 20px;
  font-size: 11px;
}

.price .type {
  display: flex;
}

.block3 {
  display: flex;
  justify-content: space-between;
}

.block3 div {
  margin-top: 10px;
  width: 100%;
  margin-right: 10px;
}

.block3 .locationField {
  min-height: 45px;
  width: 100%;
  border-radius: 5px;
  padding: 8px 5px;
  border: 1px solid #007373;
  box-sizing: border-box;
}

.serviceType {
  display: flex;
}

.block4 {
  display: flex;
  justify-content: flex-end;
}

.block4 div {
  margin: 10px;
  border: #007373 solid 2px;
  padding: 6px;
  min-width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  border-radius: 5px;
  font-weight: 600;
  color: rgb(53, 53, 53);
  cursor: pointer;
}

.button2 {
  background-color: #007373;
  color: white !important;
}

:global(.checkbox) {
  padding: 0;
}

@media only screen and (max-width: 768px) and (orientation: landscape) {
  .wrapper {
    padding: 0 5px;
  }
  .scroll {
    max-height: 180px;
  }
}

@media only screen and (max-width: 768px) and (orientation: portrait) {
  .wrapper {
    padding: 0;
  }

  .price {
    width: auto;
  }
  .priceRange {
    display: block;
    width: auto;
  }
  .priceRange .rangeLabel {
    position: absolute;
    top: 20px;
    bottom: 20px;
    left: 40px;
    height: auto;
    margin-left: 20px;
  }
  .block3 {
    display: block;
  }
  .scroll {
    font-size: 13px;
  }
  .priceRange .rangeLabel2 {
    position: absolute;
    top: 50%;
    left: 40px;
    white-space: nowrap;
    transform: translateY(-50%);
  }
  .block4 {
    justify-content: center;
  }
}

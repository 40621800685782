:global(#root){

}
.wrapper{
  margin: 0 auto;
  /*max-width: 1700px;*/
  background: #fff;
  /*-webkit-box-shadow: 0px 0px 11px 1px rgb(0 0 0 / 3%);*/
  /*-moz-box-shadow:  0px 0px 11px 1px rgb(0 0 0 / 3%);*/
  /*box-shadow:  0px 0px 11px 1px rgb(0 0 0 / 3%);;*/
  /*flex: 90%;*/
}

/* .wrapperLeft {
  background-color: #fff;
  flex: 2%;
  z-index: 1300;
  box-shadow: inset 0px 0px 24px 0px rgba(0,0,0,0.26);
}

.wrapperRight {
  background-color: #fff;
  flex: 2%;
  z-index: 1300;
  box-shadow: inset 0px 0px 24px 0px rgba(0,0,0,0.26);
} */

/*.wrapperLeft {*/
/*  background-color: #fff;*/
/*  !* padding: 10px; *!*/
/*  flex: 2%;*/
/*  z-index: 1300;*/
/*  !* margin-right: 50px; *!*/
/*  box-shadow: inset 0px 0px 24px 0px rgba(0,0,0,0.26);*/
/*}*/

/*.wrapperRight {*/
/*  background-color: #fff;*/
/*  !* padding: 10px; *!*/
/*  flex: 2%;*/
/*  z-index: 1300;*/
/*  !* margin-right: 50px; *!*/
/*  box-shadow: inset 0px 0px 24px 0px rgba(0,0,0,0.26);*/

/*}*/

/*.flex {*/
/*  display: flex;*/
/*}*/

/* .flex {
  display: flex;
} */

@media only screen and (max-width: 768px) {
  /* .wrapperLeft {
    display: none;
  }
  .wrapperRight {
    display: none;
  }
  .flex {
    display:inherit;
  } */
}

@media only screen and (min-width: 1024px) and (max-width: 1366px) {
  /* .wrapperLeft {
    display: none;
  }
  .wrapperRight {
    display: none;
  } */
}
/*@media only screen and (max-device-width: 768px) {*/
/*  .wrapperLeft { */
/*    display: none;*/
/*  }*/
/*  .wrapperRight {*/
/*    display: none;*/
/*  }*/
/*  .flex {*/
/*    display:inherit;*/
/*  }*/
/*}*/

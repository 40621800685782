.reactSwitchCheckbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.reactSwitchLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100px;
  height: 40px;
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
}

.reactSwitchLabel .reactSwitchButton {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 55px;
  height: 40px;
  border-radius: 30px;
  transition: 0.2s;
  background: #007373;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.reactSwitchCheckbox:checked + .reactSwitchLabel .reactSwitchButton {
  left: calc(100% - 0px);
  transform: translateX(-99%);
}

.reactSwitchLabel:active .reactSwitchButton {
  width: 60px;
}

@media only screen and (max-width: 750px) {
  .reactSwitchLabel {
    width: 75px;
    height: 35px;
  }

  .reactSwitchButton {
    width: 43px !important;
    height: 35px !important;
  }
}

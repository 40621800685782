.base {
  display: flex;
  flex-direction: column;
  padding: 80px 35px;
}

.content {
  padding: 25px;
  background-color: #007373;
  display: flex;
}

.left {
  width: 50%;
}

.right {
  width: 50%;
}

.step_base {
  display: flex;
}

.step_base > div {
  width: 50%;
}

.step_bar {
  display: flex;
  gap: 10px;
}

.step_bar > div {
  height: 4px;
  width: 25%;
  background: gray;
}

.step_bar_current {
  background: #007373 !important;
}

.step_button_set {
  display: flex;
  gap: 20px;
  align-items: center;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 20px;
  background: #007373;
  color: white;
  cursor: pointer;
  text-decoration: none;
}

.step_tick_item {
  display: flex;
  align-items: center;
  gap: 5px;
}

.step_title {
  color: #007373;
  font-weight: 800;
  font-size: 30px;
}

.step_image{
  width: 100%;
  height: 400px;
  object-fit: contain;
}

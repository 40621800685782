.base {
  position: relative;
  border-radius: 10px;
  max-width: 400px;
  color: white;
  font-family: 'Roboto', sans-serif;
  overflow: hidden;
  box-shadow: 4px 14px 28px -9px rgba(168, 168, 168, 0.75);
}
.base a {
  text-decoration: none;
}
.base .image_container {
  position: relative;
  line-height: 0;
}
.base img {
  object-fit: cover;
  /* opacity: 0.2; */
  z-index: 0;
  height: 150px;
  width: 100%;
}

.overlay {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAH0lEQVQYV2NkQAX/GZH4/xkYGBhhAmAOSBJEwDkgAQCCrgQEjpMcPgAAAABJRU5ErkJggg==')
    repeat;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  z-index: 0;
  transition: all 300ms ease-in-out;
}

.content {
  display: flex;
  flex-direction: column;
  margin: 0;
  z-index: 1;
  color: #404040;
  padding: 15px 10px;
  background: #ffffff;
  font-size: 12px;
  position: relative;
}
.content p {
  margin: 0;
}

.price {
  font-size: 28px;
  line-height: 28px;
  font-weight: bold;
}
.price small {
  margin-right: 5px;
}
.content .title {
  font-size: 17px;
  margin-bottom: 10px;
}
.rating_container {
  position: absolute;
  right: 10px;
  top: 20px;
}

.button {
  position: absolute;
  bottom: 20px;
  align-self: center;
  border: white solid 2px;
}
.share_container {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1;
  display: none;
}
.base:hover .share_container {
  display: block;
}

.base:hover .overlay {
  background: none;
}
.share_container_inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.share_container a {
  display: block;
  margin: 15px 0;
}
.share_container .icon {
  margin-right: 15px;
}
.share_container_inner > a {
  cursor: pointer;
}

.base {
  position: relative;
  color: #404040;
  font-family: 'Roboto', sans-serif;
  overflow: hidden;
  font-size: 14px;
}
.base img {
  max-width: 100%;
}
.bio_title {
  font-size: 36px;
  line-height: 44px;
  color: #404040;
  font-weight: bold;
  margin: 0 0 20px;
  text-transform: capitalize;
  vertical-align: middle;
}
.bio_title:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}
.bio_title .title {
}
.bio {
  line-height: 24px;
  white-space: pre-line;
  margin: 30px 0;
}
.profileContactContainer {
  margin: 30px 0;
}
.profileBadges::after {
  content: ' ';
  display: block;
  clear: both;
}
.best_seller {
  background: #01cc01;
  padding: 4px 15px;
  color: #fff;
  border-radius: 5px 0 0 5px;
  display: inline-block;
  position: relative;
  float: left;
}
.best_seller::after {
  position: absolute;
  content: ' ';
  display: block;
  top: 0;
  left: 100%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 16.5px 0 16.5px 20px;
  border-color: transparent transparent transparent #01cc01;
}
.tags {
  display: block;
  margin: 0 -5px 10px;
}
.tags:after {
  display: block;
  clear: both;
  content: ' ';
}
.tag {
  background: #007373;
  padding: 3px 12px;
  border-radius: 5px;
  margin: 2px 5px;
  color: #fff;
  float: left;
}

.prePlayer {
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.thumbnailImage {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
}

.playIcon {
  font-size: 50px;
  color: #ffffff;
  cursor: pointer;
  z-index: 1;
}

.videoIframe {
  height: 500px;
  width: inherit;
}

.fullscreenButton {
  position: absolute;
  top: 10px;
  right: 10px;
}

.expandIcon {
  color: white;
  font-size: 40px;
  cursor: pointer;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
}

@media only screen and (max-width: 768px) {
  .bio_title .title {
    font-size: 32px;
    line-height: 30px;
    margin-top: 20px;
  }
}

.base{
  display: flex;
  margin-top: 30px;
}

.sentBase{
  flex-direction: row-reverse;
}

.image{
  height: 50px;
  width: 50px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid rgb(184, 184, 184);
}

.image > img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.message{
  background-color: #efe6d7;
  border-radius: 6px;
  padding: 20px;
  max-width: 350px;
  margin: 0 30px;
}

.sentMessage{
  background-color: #e3e3e3;
}

.message > p{
  margin:0;
}

.messageBase{
  display: flex;
  flex-direction: column;
}

.time{
  align-self: flex-end;
  margin: 0 30px 0 0;
}

.wrapper{
  display: flex;
  flex-direction: column;
}

.category > div {
  display: flex;
  justify-content: space-between;
}

.categories{
  display: flex;
  gap: 20px;
}

.select_dot{
  height: 40px;
  width: 40px;
  border-radius: 999px;
  background-color: whitesmoke;
  border: 1px solid black;
}

/*.category > div > div{*/
/*  border: 1px solid rgba(144, 144, 144, 0.705);*/
/*  min-width: 240px;*/
/*  height: 70px;*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  border-radius: 9px;*/
/*  margin-bottom: 20px;*/
/*  cursor: pointer;*/
/*  font-size: 20px;*/
/*}*/

.title{
  display: flex;
  flex-direction: column;
}

.program > select{
  width: 100%;
  min-height: 60px;
  border-radius: 9px;
  border: 1px solid #807f7d;
}

.description{
  display: flex;
  flex-direction: column;
}

.info{
  align-self: flex-end;
  color: rgb(194, 194, 194)
}

.selectedCategory{
  background-color: #007373;
  border: 1px solid #007373 !important;
  color: white;
  box-shadow: 4px 14px 28px -9px rgba(168, 168, 168, 0.75);
}

.label{
  font-weight: 500;
  font-size: 19px;
}

.input{
  border-radius: 9px;
  border: 1px solid rgba(144, 144, 144, 0.705);;
  min-height: 60px;
  width: 100%;
  font-size: 20px;
  color: rgb(80, 80, 80);
}

.input::placeholder{
  font-size: 20px;
}

.map{
  margin-top: 30px;
  width: 100%;
  height: 500px;
  background-color: #807f7d;
  border-radius: 9px;
  overflow: hidden;
}

.map img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.errors{
  /*padding: 20px;*/
  /*background-color: #00acee;*/
  /*border-radius: 10px;*/
}

.error{
  color: rgb(255 91 91);
  font-size: 20px;
  padding: 20px;
  background-color: #f1f1f11a;
  border-radius: 10px;
  width: -moz-fit-content;
  width: fit-content;
  box-shadow: 1px 1px 15px 2px #e6e6e6;
  font-style: italic;
}

.next{
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  margin: 25px;
  margin-right: 0;
}

.next p{
  margin-right: 20px;
  font-size: 26px;
  color: rgb(66, 66, 66)
}

.button{
  height: 45px;
  width: 140px;
  background-color: #007373;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  margin-top: 20px;
  align-self: flex-end;
}

@media only screen and (max-width: 768px) {
  .wrapper {
    padding: 20px;
  }

  .category > div {
    flex-direction: column;
  }
}

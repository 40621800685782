.base{
  position: absolute;
  font-family: 'font-awesome';
  left: 100%;
  top: 50%;
  margin-left: 20px;
  transform: translateY(-50%);
  width: 280px;
}
.tip_icon{
  color: #6005ff;
  font-size: 28px;
}
.tip_message{
  position: absolute;
  display: none;
  left: 60px;
  top: 50%;
  transform: translateY(-50%);
  padding: 15px;
  border-radius: 5px;
  background: #007bff;
  color: #f1ecff;
  font-style: italic;
}
.tip_message:before{
  content: ' ';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 18px 15px 0;
  border-color: transparent #007bff transparent transparent;
  position: absolute;
  left: -18px;
  top: 50%;
  margin-top: -15px;
}
.base:hover .tip_message{
  display: block;
}

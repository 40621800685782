.base {
  margin: 0;
  width: 100%;
  font-family: 'Roboto', sans-serif;
}

.base_inner {
  margin: 0;
  height: 750px;
  position: relative;
}

.search_box {
  width: 500px;
  margin-top: 80px;
  flex: 1;
}

.main_title {
  font-size: 40px;
  margin: 0;
  color: white;
  flex: 1;
}

.title_wrapper {
  position: absolute;
  left: 0;
  top: 25px;
  display: flex;
  align-items: center;
}

.img {
  opacity: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.4s ease-out;
  filter: brightness(0.7);
}

.activeImage {
  opacity: 1;
}

.backgroundShape {
  position: absolute;
  top: 0;
  right: 0;
  width: 57%;
  height: 100%;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAH0lEQVQYV2NkQAX/GZH4/xkYGBhhAmAOSBJEwDkgAQCCrgQEjpMcPgAAAABJRU5ErkJggg==')
    repeat;
  background-color: rgba(34, 34, 34, 0.541);
  clip-path: polygon(25% 0, 100% 0, 100% 100%, 0% 100%);
}

.gradientDiv {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.71) 0%, rgba(0, 0, 0, 0) 60%);
}

.contentWrapper {
  position: relative;
  padding: 0 70px;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.contentInner {
  position: relative;
  height: 100%;
}

.rightDiv {
  display: flex;
  flex-direction: column;
  width: 40%;
  height: 100%;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
  color: white;
  position: absolute;
  top: 0;
  right: 0;
}

.rightDiv > p:nth-child(1) {
  font-size: 22px;
  margin: 0;
}

.rightDiv > p:nth-child(2) {
  font-size: 35px;
  margin: 0;
  font-weight: 600;
  margin-top: 0;
}

.searchDiv {
  display: flex;
  height: 60px;
  width: 100%;
  overflow: hidden;
  margin-top: 25px;
  transition: all 200ms ease-out;
}

.searchDiv:hover {
  filter: brightness(95%);
}

.searchDiv div1 {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #5d5d5d;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.searchDiv div img {
  width: 60%;
}

.searchDiv input {
  padding: 25px;
  font-size: 17px;
  width: 100%;
}

.searchDiv input::placeholder {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
}

.leftDiv {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 0 30px 0;
  color: white;
  width: 100%;
  font-family: 'Roboto', sans-serif;
}

/*.leftDiv > p {*/
/*  margin: 0;*/
/*}*/

/*.leftDiv > p:nth-child(1) {*/
/*  font-size: 40px;*/
/*  font-weight: 500;*/
/*  font-family: 'Acumin Pro', serif;*/
/*  margin-bottom: 40px;*/
/*  color: white;*/
/*}*/

/*.leftDiv > p:nth-child(2) {*/
/*  font-weight: 600;*/
/*  font-size: 35px;*/
/*}*/

/*.leftDiv > p:nth-child(3) {*/
/*  margin-bottom: 20px;*/
/*  font-size: 22px;*/
/*}*/
.find_coach_mobile {
  display: none;
  padding: 15px;
}
.find_coach_mobile .big_title {
  font-weight: 300;
  font-size: 27px;
  color: #404040;
  margin: 0;
  line-height: 30px;
}
.find_coach_mobile .small_title {
  font-size: 24px;
  margin: 0;
  font-weight: 600;
}
.find_coach_mobile .sub_text {
  margin: 10px 0 25px;
}

.slider_base {
  display: none;
}

.carouselButtons {
  display: flex;
  background-color: #4242426e;
  margin-top: 20px;
  justify-content: center;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 30px;
  backdrop-filter: blur(25px);
}

.carouselButtonsBase {
  display: flex;
  justify-content: center;
}

.carouselButtons > p {
  margin: 10px 28px;
  cursor: pointer;
  color: #7c7c7c;
  font-size: 15px;
  transition: all 150ms ease-out;
}

.selectedCaruselButton {
  color: #e0e0e0 !important;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #007373;
  height: 63px;
  width: 265px;
  border-radius: 8px;
  color: #ffffff;
  transition: all 300ms ease-out;
}

.button:hover {
  opacity: 0.9;
}

.second_title {
  font-size: 40px;
  margin-bottom: -20px;
}

.dynamic_title {
  font-size: 30px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .rightDiv {
    width: 100%;
  }
  .find_coach_mobile {
    display: block;
    padding: 25px 30px;
  }
}

@media only screen and (max-device-width: 730px) {
  .search_box {
    display: none;
  }
}

@media only screen and (max-device-width: 1200px) {
  .title_wrapper {
    flex-direction: column;
  }
}

@media only screen and (max-device-width: 768px) {
  .rightDiv {
    width: 100%;
    text-align: right;
  }
  .find_coach_mobile {
    display: block;
    padding: 25px 30px;
  }
  .base_inner {
    height: 450px;
  }
  .contentWrapper {
    padding: 0 50px;
  }

  .backgroundShape {
    width: 100%;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 40% 100%);
  }

  /*.leftDiv > p:nth-child(1) {*/
  /*  font-size: 20px;*/
  /*}*/

  /*.rightDiv > p:nth-child(2) {*/
  /*  font-size: 22px;*/
  /*  font-weight: 600;*/
  /*}*/
  .searchDiv input {
    padding: 5px 25px;
  }

  /*.rightDiv > p:nth-child(1) {*/
  /*  font-size: 16px;*/
  /*}*/

  /*.rightDiv > p:nth-child(2) {*/
  /*  font-size: 24px;*/
  /*  font-weight: 600;*/
  /*  margin-top: 0px;*/
  /*}*/
}

@media only screen and (max-device-width: 835px) {
  /*.leftDiv > p:nth-child(2) {*/
  /*  font-size: 25px;*/
  /*}*/

  /*.leftDiv > p:nth-child(3) {*/
  /*  font-size: 15px;*/
  /*}*/

  /*.rightDiv > p:nth-child(2) {*/
  /*  font-size: 25px;*/
  /*}*/

  /*.rightDiv > p:nth-child(3) {*/
  /*  font-size: 25px;*/
  /*}*/

  /*.base_inner {*/
  /*  height: 450px;*/
  /*}*/

  .main_title {
    font-size: 40px;
    text-align: center;
  }
  .base_inner {
    height: 600px;
    margin: 0;
  }

  .second_title {
    font-size: 30px;
    margin-bottom: -20px;
  }

  .dynamic_title {
    font-size: 20px;
  }
}

@media only screen and (max-device-width: 600px) {
  .carouselButtons {
    width: 100%;
    justify-content: space-around;
  }

  .carouselButtons > p {
    margin: 20px 0;
  }

  .search_box {
    width: 290px;
    margin-top: 50px;
  }

  .base_inner {
    height: 650px;
  }

  .dynamic_title {
    font-size: 15px;
    color: white;
  }

  .main_title {
    font-size: 30px;
    line-height: 1;
  }
}

.wrapper{
  padding: 20px;
  max-width: 1700px;
  margin: 0 auto;
  position: relative;
}

@media only screen and (max-width: 768px) {
  .wrapper{
    padding: 20px 20px 20px 10px;
  }
}

.base{
  padding: 10px;
  box-shadow: 4px 14px 28px -9px rgba(168, 168, 168, 0.75);
  border-radius: 9px;
  margin-top: 20px;
}

.row{
  display: flex;
  flex-direction: column;
}

.row > div{
  display: flex;
  justify-content: space-between;
}

.row > div > p{
  margin: 20px 0 0 0;
}

.bold{
  font-size: 18px;
  font-weight:600;
  margin: 12px 0 0 0;
}

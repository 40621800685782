.button {
  height: 50px;
  min-width: 100px;
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-size: 17px;
  border-radius: 5px;
  /*box-shadow: 4px 14px 28px -9px rgba(168, 168, 168, 0.75);*/
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 200ms ease-out;
}

.link {
  padding: 20px 30px;
}

.button:hover {
  filter: brightness(90%);
}

.primary {
  background-color: #007373;
}

.secondary {
  background-color: #007373;
}

.facebook {
  background-color: #3b5999;
}

.twitter {
  background-color: #00acee;
}

.linkedin {
  background-color: #0e76a9;
}

.buttonwidth100 {
  display: block;
}

.textonly {
  height: 50px;
  min-width: 145px;
  font-family: 'Roboto', sans-serif;
  font-size: 17px;
  color: #007373;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text_with_border {
  height: 27px;
  min-width: 145px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #5d5d5d;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #5d5d5d;
  border-radius: 5px;
  font-style: italic;
  margin: 0 auto;
}

.textWithIcon {
  height: 50px;
  width: fit-content;
  min-width: 145px;
  color: white;
  font-family: 'Roboto', sans-serif;
  font-size: 17px;
  color: #007373;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  padding: 10px;
  font-size: 20px;
  color: white;
}

.textonlyicon {
  padding: 10px;
  font-size: 20px;
  color: #007373;
}

.rotate {
  transform: rotate(180deg);
}

.iconPayPal {
  padding: 10px;
  font-size: 40px;
  color: white;
}

.searchBlock {
  display: flex;
  height: 60px;
  border-radius: 5px;
  overflow: hidden;
  background: #fff;

  /* box-shadow: 4px 14px 28px -9px rgba(168, 168, 168, 0.75); */
}

.searchBlock input {
  border: none;
  padding: 0 25px;
}

.searchBlock input::placeholder {
  font-family: 'Roboto', sans-serif;
  font-style: italic;
}

.coachField {
  width: 100%;
  font-weight: 700;
  font-size: 15px;
  z-index: 150;
}

.locationField {
  width: 100%;
  font-weight: 700;
  font-size: 15px;
  z-index: 150;
}

.searchButton {
  width: 60px;
  background-color: #007373;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0 10px;
  z-index: 150;
}

.searchIcon {
  color: white;
  font-size: 23px;
}

.filterButton {
  height: 60px;
  width: fit-content;
  background-color: #333333;
  border-radius: 9px;
  display: flex;
  align-items: center;
  margin-left: 3vw;
  box-shadow: 4px 14px 28px -9px rgba(168, 168, 168, 0.75);
  cursor: pointer;
  color: #007373;
}

.filterIcon {
  padding: 20px 0 20px 20px;
  font-size: 20px;
}

.filterLable {
  padding: 20px;
  font-size: 16px;
  font-weight: 600;
}

@import '../../marketplace.css';

/* 404 page */

.root {
  /* Expand to the available space */
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  max-width: 587px;
  width: 80%;
  margin-top: 60px;
  margin-bottom: 60px;

  @media (--viewportMedium) {
    margin-top: 10vh;
  }
}

.number {
  @apply --marketplaceHeroTitleFontStyles;
  text-align: center;
  color: var(--marketplaceColor);
  margin: 1px 0 0 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.heading {
  @apply --marketplaceModalTitleStyles;
  text-align: center;
  margin-top: 16px;

  @media (--viewportMedium) {
    margin-top: 22px;
  }
}

.description {
  text-align: center;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 24px;
    margin-bottom: 0;
  }
}

.searchForm {
  max-width: 408px;
  margin: 62px auto 0 auto;

  background-color: var(--matterColorLight);
  border-bottom: 4px solid var(--marketplaceColor);
  box-shadow: var(--boxShadowNotFoundPageSearch);

  @media (--viewportMedium) {
    margin-top: 64px;
  }
}

.errorContainer {
  font-family: 'Roboto', sans-serif;
  text-align: center;
  padding: 50px 0;
}
.oops {
  color: #007373;
  font-size: 130px;
  font-weight: 800;
}
.notfound_2 {
  font-size: 36px;
  color: #007373;
  font-weight: 600;
}
.notfound_2 span {
  color: #404040;
}
.notfound_3 {
  font-size: 22px;
  color: #007373;
  font-weight: 400;
  margin-bottom: 30px;
}

.notfoundimage {
  width: 100%;
}

@media only screen and (max-width: 900px) {
  .oops {
    font-size: 100px;
    font-weight: 600;
  }
}

.upload_image{
  width: 200px;
  height: 200px;
  object-fit: contain;
}

.input_cover{
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.input_cover > p {
  margin: 0;
  font-weight: 600;
}

.input_cover > input {
  height: 30px;
  font-size: 20px;

}

.button{
  height: 45px;
  width: 140px;
  background-color: #007373;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  border: 0;
  margin-top: 20px;
  border-radius: 2px;
}

.button_gray{
  height: 45px;
  width: fit-content;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #d5d5d5;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #151515;
  cursor: pointer;
  border: 0;
  margin-top: 20px;
  border-radius: 2px;
}

.title{
  font-size: 25px;
  font-weight: 600;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  white-space: nowrap;
}

.navBottom {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 30px;
}

/*.bottomLink{*/
/*  text-decoration: none;*/
/*  color: #007373;*/
/*  font-size: 17px;*/
/*  margin-right: 20px;*/
/*  white-space: nowrap;*/
/*}*/

.navbar .logo {
  flex: 1;
  padding-left: 10px;
}

/* .navLogo {
  flex: 1;
  padding-left: 10px;
} */

.navLogo {
  flex: 1;
  padding-left: 20px;
  padding-right: 20px;
  /* cursor: pointer; */
}

.navbar div img {
  height: 45px;
  cursor: pointer;
}

.navbar > div > img {
  cursor: pointer;
}

.guest_buttons {
  display: flex;
  align-items: center;
  z-index: 50;
}

/* when authenticated */
.profile {
  display: flex;
  position: relative;
  align-items: center;
}

.name {
  margin: auto 20px;
  cursor: default;
}

.img {
  border-radius: 100%;
}

.actions {
  position: absolute;
  top: 55px;
  right: 0px;
  background-color: white;
  box-shadow: -2px 7px 20px 2px rgb(130 130 130 / 40%);
  border-radius: 5px;
  overflow: hidden;
  visibility: hidden;
  width: 150px;
  z-index: 1000;
}

.actions > p {
  padding: 10px;
  margin: 0;
  transition: background-color 300ms ease-out;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.actions p:hover {
  background-color: #007373;
}

.notification {
  width: 15px;
  height: 15px;
  background-color: red;
  border-radius: 100%;
  margin-left: -13px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notification > p {
  font-size: 14px;
  font-weight: 600;
  color: white;
}

.actionBadge {
  /* height: 10px;
  width: 10px;
  background-color: #aa6666;
  border-radius: 50%;
  margin-right: 10px;
  padding: 5px; */
  color: red;
}

.open {
  visibility: visible;
}

.toggleNav {
  height: 20px;
  display: none;
  margin-right: 15px;
}
.toggleNav::before {
  content: ' ';
  display: block;
  height: 3px;
  width: 32px;
  background: #007373;
  border-radius: 5px;
  margin-bottom: 5px;
}
.toggleNav span {
  content: ' ';
  display: block;
  height: 3px;
  width: 28px;
  background: #007373;
  border-radius: 5px;
}
.toggleNav::after {
  content: ' ';
  display: block;
  height: 3px;
  width: 32px;
  background: #007373;
  border-radius: 5px;
  margin-top: 5px;
}

.navLinks {
  display: flex;
}
.flex {
  display: flex;
  width: 50%;
}

.navLink {
  text-decoration: none;
  color: #686464;
  font-size: 17px;
  margin-right: 20px;
  white-space: nowrap;
  padding-bottom: 5px;
}

.navLinkMobile {
  text-decoration: none;
  color: #007373;
  font-size: 17px;
  margin-right: 20px;
  white-space: nowrap;
  text-align: center;
}

/*.navLink:active {*/
/*  color: #007373;*/
/*}*/

.navLinkActive {
  border-bottom: 1px solid #686464;
}

.navLinkMobileActive {
  font-weight: 900;
}

.mobileNav {
  flex-direction: column;
  position: fixed;
  height: 100%;
  width: 300px;
  background-color: #393939;
  z-index: 50;
  left: 0;
  top: 0;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  /*border-right: 7px solid #007373;*/
  overflow-y: hidden;
}

.mainPageLinksMobile {
  display: flex;
  justify-content: space-between;
}

.mobileNavClose {
  display: none;
}

.mobileNavOpen {
  display: flex;
}

.betaTag {
  position: absolute;
  font-size: 10px;
  top: 5px;
  right: 5px;
  font-style: italic;
  color: red;
}

.mobileSideMenuCloseButton {
  align-self: flex-end;
}

.mainHamburgerMenu {
  /* margin-left: 10px; */
  display: flex;
  flex-direction: column;
  width: 25px;
  justify-content: center;
  cursor: pointer;
  z-index: 50;
}

.mainHamburgerMenu > span {
  width: 100%;
  padding-top: 5px;
  border-bottom: 3px solid #007373;
}

.mobileMenuCloseIcon {
  color: #007373;
  font-size: 27px;
  padding: 10px 10px 0 0;
}

.collapsibles {
  margin-top: 20px;
}

.colHead {
  background-color: #007373 !important;
  border-radius: 50px;
  margin: 10px;
  width: -webkit-fill-available !important;
}

.colBody {
  background-color: #393939 !important;
}

.colContent {
  display: flex;
  flex-direction: column;
}

.colContent > a {
  text-decoration: none;
  padding: 15px 0;
  color: white;
}

.activeColContent {
  font-weight: 900;
}

/*.navbar a:hover {*/
/*  color: #007373 !important;*/
/*}*/

#menuPinIcon:hover {
  color: #007373 !important;
}

.mobContent {
  visibility: hidden;
  height: 0px;
}

.navbar a svg:hover {
  color: #007373 !important;
}

.MuiBadgeAnchorOriginTopRightRectangle {
  top: 0;
  right: 0;
  transform: scale(1) translate(50%, -50%);
  transform-origin: 100% 0%;
}

.MuiBadgeColorSecondary {
  color: #fff;
  background-color: #f50057;
}

.MuiBadgeBadge {
  height: 20px;
  display: flex;
  padding: 0 6px;
  z-index: 1;
  position: absolute;
  flex-wrap: wrap;
  font-size: 0.75rem;
  min-width: 20px;
  box-sizing: border-box;
  transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  align-items: center;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  line-height: 1;
  align-content: center;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
  margin-top: 5px;
}

.MuiSvgIconRoot {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
}

.MuiBadgeRoot {
  display: inline-flex;
  position: relative;
  flex-shrink: 0;
  vertical-align: middle;
  margin-right: 5px;
  background: #cbc7c7;
  padding: 10px;
  border-radius: 50px;
}

.notificationsDiv {
  display: inline-flex;
  position: relative;
  flex-shrink: 0;
  vertical-align: middle;
  margin-right: 5px;
  background-color: #dddddd;
  padding: 5px;
  border-radius: 50px;
  margin-left: 5px;
}

.badge {
  border-radius: 100%;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.badgeCount {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: red;
  color: whitesmoke;
  border-radius: 100%;
  width: 15px;
  height: 15px;
  position: absolute;
  top: -5px;
  right: -5px;
  font-size: 11px;
  font-weight: 900;
}

.marginTop {
  margin-top: 5px;
}
/*a:active, a:focus {*/
/*  outline: 0;*/
/*  border: none;*/
/*  -moz-outline-style: none;*/
/*}*/

/*button:active, button:focus {*/
/*  outline: 0;*/
/*  border: none;*/
/*  -moz-outline-style: none;*/
/*}*/

/*a:hover {*/
/* color: #007373 !important;*/
/*}*/

/* .navbar a {
  text-decoration:  none;
  color: #404040 !important;
} */

.popup {
  height: 530px;
  width: 100%;
  border-radius: 9px;
  overflow: hidden;
}

.iframe {
  height: 100%;
  width: 100%;
}

.nonSelected:hover {
  /*color: rgba(214, 173, 113, 1);*/
  color: #007373;
}

.sidenav {
  height: 100%;
  width: 0;
  position: absolute;
  z-index: 100;
  /* top: 0;
  left: 0; */
  background-color: #fff;
  overflow-x: hidden;
  transition: width 0.5s;
  padding-top: 43px;
  font-family: 'Roboto', sans-serif;
  /* opacity: 0.9; */
  box-shadow: -1px 20px 10px 0px #888888;
}

.sidenav hr {
  border: 1px solid #e1deda;
}

.sidenav a {
  padding: 8px 8px 0px 32px;
  text-decoration: none;
  font-size: 17px;
  color: #404040;
  display: block;
  transition: width 0.3s;
}

.sidenav a h1 {
  font-size: 18px;
  /* margin: 10px 0px -5px 0px; */
  margin: 10px 0px 0px 0px;
  color: #007373;
  font-weight: 500;
}

.sidenav a h1:hover {
  text-decoration: underline;
}

.sidenav a:hover {
  color: #007373;
}

.sidenav .closeBtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
  padding: 8px 8px 0px 0px;
}

.sidenav .pinBtn {
  position: absolute;
  top: 12px;
  right: 0px;
  font-size: 20px;
  margin-left: 50px;
  padding: 8px 8px 0px 0px;
}

.customNotificationWrapper {
  height: 40px;
  width: 100%;
  position: absolute;
  top: 70px;
  left: 0;
  display: flex;
  align-items: center;
}

.notificationMessage {
  color: white;
  padding-left: 10px;
}

.notificationCloseButton {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.notificationTypeIcon {
  color: white;
  padding-left: 10px;
}

.stepsBase {
  position: absolute;
  right: 20px;
  top: 80px;
  z-index: 10;
}

.closeMenu {
  margin: 0 auto;
  padding-left: 0px;
  transition: padding-left 0.5s;
}

.openMenu {
  padding-left: 200px;
  /* transition: padding-left 0.5s; */
}

.logoLink {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  /*height: 180px;*/
  top: -20px;
}

.logoLink > img {
  margin-top: 20px;
  width: 200px;
  filter: grayscale(100%);
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }

  .menuPinIcon {
    display: none !important;
  }

  .mobContent {
    visibility: visible;
    height: auto;
  }

  .sidenav .closeBtn {
    right: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .toggleNav {
    display: inline-block;
  }
}

@media only screen and (min-width: 375px) and (max-width: 812px) and (-webkit-device-pixel-ratio: 3) {
  .toggleNav {
    display: inline-block;
  }
}

@media only screen and (min-width: 375px) and (max-width: 667px) {
  .toggleNav {
    display: inline-block;
  }

  .menuPinIcon {
    display: none !important;
  }

  .mobContent {
    visibility: visible;
    height: auto;
  }

  .sidenav .closeBtn {
    right: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .guest_buttons a {
    padding: 0 10px;
    min-width: auto;
  }
  .guest_buttons div {
    min-width: 50px;
    height: 40px;
  }
  .guest_buttons div a {
    padding: 10px 15px;
  }

  .header-burger {
    display: none;
  }

  .bm-burger-button {
    position: absolute;
    width: 25px;
    height: 20px;
    left: 10px;
    top: 185px;
  }

  .bm-burger-bars {
    background: #007373;
  }

  /* .navLogo {
    flex: 1;
    padding-left: 20px;
    padding-right: 20px;
  } */

  .sidenav {
    height: 100%;
    width: 0;
    position: absolute;
    z-index: 100;
    left: 0 !important;
    background-color: #fff;
    overflow-x: hidden;
    transition: width 0.5s;
    padding-top: 55px;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  }

  .sidenav a {
    color: #404040;
  }

  /* #mesengerDiv , #notificationsDiv {
    display: none;
  }     */

  .profile > div:nth-child(1) {
    display: none;
  }

  .profile > div:nth-child(2) {
    display: none;
  }

  .navbar .logo {
    flex: 1;
    padding-left: 10px;
  }

  .navLogo {
    flex: 1;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media only screen and (max-width: 400px) {
  .mobileNav {
    justify-content: unset;
  }

  .logo > img {
    width: 100px;
    height: 35px !important;
  }

  .logo {
    display: flex;
    align-items: center;
  }

  .menuPinIcon {
    display: none !important;
  }

  .sidenav .closeBtn {
    right: 10px;
  }

  .mobContent {
    visibility: visible;
    height: auto;
  }
}

@media only screen and (max-width: 700px) {
  .navLinks {
    display: none;
  }

  .mainHamburgerMenu {
    display: flex;
  }

  .menuPinIcon {
    display: none !important;
  }

  .mobContent {
    visibility: visible;
    height: auto;
  }

  .sidenav .closeBtn {
    right: 10px;
  }
}

/*
@media only screen
  and (min-width: 1024px)
  and (max-width: 1366px)
  and (-webkit-min-device-pixel-ratio: 2) {

    .bm-burger-button {
      position: fixed;
      width: 36px;
      height: 30px;
      left: 36px;
      top: 36px;
    }
} */

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  /* .mobContent {
    visibility: visible;
    height: auto;
  } */
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: landscape) {
  .popup {
    height: 560px;
  }

  .popup-content {
    width: 80%;
  }

  .mobContent {
    visibility: visible;
    height: auto;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .popup {
    height: 750px;
  }

  .popup-content {
    width: 80%;
  }

  .mobContent {
    visibility: hidden;
    height: 0px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1366px) {
  .popup {
    height: 560px;
  }

  .popup-content {
    width: 80%;
  }

  .mobContent {
    visibility: hidden;
    height: 0px;
  }
}

@media only screen and (max-width: 600px) {
  .menuPinIcon {
    display: none !important;
  }

  .sidenav .closeBtn {
    right: 10px;
  }

  .mobContent {
    visibility: visible;
    height: auto;
  }
}

@media only screen and (max-width: 1000px) {
  .logoLink > img {
    width: 200px;
  }
}

@media only screen and (max-width: 500px) {
  .logoLink {
    height: 80px;
    left: 10px;
    top: 10px;
    transform: unset;
  }

  .logoLink > img {
    margin-top: 10px;
    margin-left: 30px;
    width: 150px;
  }

  .navBottom {
    margin-top: 50px;
  }
}

.base {
  display: flex;
  font-family: 'Roboto', sans-serif;
  justify-content: center;
  margin: 50px 0;
}

.searchBlock {
  display: flex;
  height: 60px;
  border-radius: 9px;
  overflow: hidden;
  border: 1px solid rgb(207, 207, 207);
  /* box-shadow: 4px 14px 28px -9px rgba(168, 168, 168, 0.75); */
}

.searchBlock input {
  border: none;
  padding: 0 25px;
}

.searchBlock input::placeholder {
  font-family: 'Roboto', sans-serif;
  font-style: italic;
}

.coachField {
  width: 22vw;
  font-family: 'Roboto', sans-serif;
  font-size: 17px;
}

.locationField {
  width: 11vw;
  font-family: 'Roboto', sans-serif;
  font-size: 17px;
}

.searchButton {
  width: 60px;
  background-color: #5d5d5d;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.searchIcon {
  color: white;
  font-size: 23px;
}

.filterButton {
  height: 60px;
  width: fit-content;
  background-color: #007373;
  border-radius: 9px;
  display: flex;
  align-items: center;
  margin-left: 3vw;
  box-shadow: 4px 14px 28px -9px rgba(168, 168, 168, 0.75);
  cursor: pointer;
  color: #ffffff;
}

.filterIcon {
  padding: 20px 0 20px 20px;
  font-size: 20px;
}

.filterLable {
  padding: 20px;
  font-size: 16px;
  font-weight: 600;
}

.resetFilter {
  cursor: pointer;
}

@media only screen and (max-width: 768px) and (orientation: portrait) {
  .searchButton {
    width: 40px;
  }
  .filterIcon {
    padding: 20px 15px;
    font-size: 16px;
  }
  .filterButton p {
    display: none;
  }
  .resetFilter {
    display: none;
  }

  .base {
    padding: 0 10px;
  }
  .searchBlock {
    flex: 1;
  }
}

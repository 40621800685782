.base {
  position: fixed;
  z-index: 200;
  height: 430px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: 3px solid #007373;
  transition: all 300ms;
  background-color: white;
  box-shadow: 0 0 20px 0 #2d2d2d57;
  left: 10px;
  width: 650px;
}

.toggleHandle {
  background-color: #007373;
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
}

.call_base {
  width: 100%;
  height: calc(100% - 30px);
  background: #dddddd;
  position: relative;
}

.remoteVideo {
  width: 100%;
  height: 100%;
  /*background: #00a699;*/
  object-fit: cover;
}

.localVideo {
  width: 40%;
  height: 40%;
  position: absolute;
  right: 0;
  bottom: 0;
  /*background: #4a4a4a;*/
  object-fit: cover;
  border-top: 3px solid #007373;
  border-left: 3px solid #007373;
  border-radius: 10px;
}

.buttons{
  position: absolute;
  left: 10px;
  bottom: 10px;
  display: flex;
  align-items: center;
}

.actionButton {
  font-size: 15px;
  width: 60px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.call_incoming {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  /*background: #0073733d;*/
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.5);
}

.call_action {
  height: 320px;
  width: 320px;
  background-color: white;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 20px 5px #d4d4d438;
}

.call_animation{
  height: 200px;
  width: 200px;
}

.call_actions{
  display: flex;
  margin-top: 10px;
}

.accept_call_action{
  height: 40px;
  width: 80px;
  background-color: #35c484;
  color: white;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  cursor: pointer;
}

.cancel_call_action{
  height: 40px;
  width: 80px;
  background-color: #c43535;
  color: white;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  margin-right: 20px;
  cursor: pointer;
}

@media only screen and (max-width: 750px) {
  .base {
    width: 100%;
    left: 0;
  }
}

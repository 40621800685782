.base {
  position: relative;
  border-radius: 10px;
  width: 100%;
  max-width: 300px;
  font-family: 'Roboto', sans-serif;
  overflow: hidden;
  box-shadow: 4px 14px 28px -9px rgba(168, 168, 168, 0.75);
  background: url(viveka.png) no-repeat;
  margin: 0 auto;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
}

.dark {
  /*background-color:#6601ff;*/
  /*color: white;*/
}
.dark p {
  /*color: #fff;*/
}

.light {
  background-color: white;
  color: black;
}

.content {
  border: 2px solid transparent;
  padding: 0 20px 20px;
  width: 100%;
}

/* .base img:first-child {
  position: absolute;
  top: -10px;
  right: -10px;
} */

.usd {
  font-size: 15px;
}

.icon {
  font-size: 35px;
  position: absolute;
  right: 15px;
  top: 15px;
  color: #007373;
}

.content p {
  font-size: 19px;
  margin: 0;
  padding: 10px 0 10px 20px;
  color: rgb(73, 73, 73);
}
.content p:last-child {
  border: none;
}
.content .title {
  font-size: 35px;
  margin: 0;
  font-weight: 350;
  padding: 50px 0 0px 25px;
}

/*.content p {
  font-size: 15px;
  margin: 0;
}*/

.desc {
  margin: 0;
  padding: 0 30px 30px 30px;
  font-size: 15px;
}

.borderDark {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.borderLight {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.selected {
  position: relative;
  border-radius: 10px;
  width: 100%;
  /* height: 600px; */
}

.selected .content {
  border: 2px solid #007373;
  border-radius: 10px;
}

.selected img:first-child {
  position: absolute;
  top: -18px;
  right: -18px;
  z-index: 1;
}

.price {
  font-size: 50px !important;
  font-weight: 900;
  text-align: center;
  padding: 0;
  line-height: 50px;
}

.oldPrice {
  font-size: 40px !important;
  color: #939393 !important;
  font-weight: 900;
  text-align: center;
  padding: 0;
  line-height: 50px;
  text-decoration: line-through;
  margin-bottom: -18px !important
;
}
.priceType {
  margin: 0;
  padding: 0;
  text-align: right;
}

.gotoButton {
  height: 50px;
  width: 90%;
  align-self: center;
  background-color: #007373;
  display: flex;
  justify-content: center;
  align-self: center;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px auto;
}

.gotoButton > p {
  color: white !important;
  font-size: 18px !important;
  padding: 10px 0;
}

.disabled {
  opacity: 0.5;
  cursor: no-drop;
}

@media only screen and (max-width: 600px) {
  .base {
    /* height: 510px; */
    margin-bottom: 20px;
  }

  .content p {
    padding: 10px 0 5px 0;
    font-size: 14px;
  }
  .content p.title {
    padding: 10px 0 0 0;
    font-size: 25px;
    font-weight: 600;
  }
  .content p:nth-child(5) {
    margin: 0 auto;
    padding: 0;
    font-size: 35px;
  }

  .content p:nth-child(6) {
    margin-left: 10%;
    padding: 0;
  }

  .content p.desc,
  .content p.price {
    font-size: 14px;
  }
  .content p.title {
    font-size: 20px;
  }

  .price {
    font-size: 35px !important;
  }

  .gotoButton > p {
    font-size: 14px !important;
    padding: 10px 0;
  }

  .gotoButton {
    height: 40px;
    width: 100%;
  }
}

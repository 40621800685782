@import '../../marketplace.css';

:root {
  --ProfileSettingsForm_avatarSize: 96px;
  --ProfileSettingsForm_avatarSizeDesktop: 240px;
}

.root {
  margin-top: 24px;
  margin: 20px;
  @media (--viewportMedium) {
    margin-top: 10px;
  }
}

.sectionContainer {
  padding: 0;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 56px;
  }
}

.form_field {
  padding: 0;
  margin-bottom: 20px;
  width: 100%;
  position: relative;
}

.form_field select {
  height: 40px;
  width: 100%;
  border-radius: 5px;
  font-family: 'Roboto', sans-serif;
  font-size: 17px;
  border: 1px solid rgb(177, 177, 177);
}

.form_field_right {
  padding: 0;
  margin-bottom: 20px;
  width: 100%;
  position: relative;
  text-align: right;
}

.about {
  /*min-height: 300px;*/
}

.about_field textarea {
  height: 300px;
  resize: vertical !important;
}

.sectionTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 12px;
  padding-top: 3px;
  padding-bottom: 3px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 24px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.lastSection {
  margin-bottom: 69px;

  @media (--viewportMedium) {
    margin-bottom: 111px;

    & .sectionTitle {
      margin-bottom: 16px;
    }
  }
}

.uploadAvatarInput {
  display: none;
}

.label {
  width: var(--ProfileSettingsForm_avatarSize);
  @media (--viewportMedium) {
    width: var(--ProfileSettingsForm_avatarSizeDesktop);
  }
}

.avatarBox {
  width: 225px;
  height: 160px;
}

.avatarPlaceholder,
.avatarContainer {
  /* Dimension */
  position: relative;

  /* Center content */
  display: flex;
  align-items: center;

  cursor: pointer;
}

.avatarPlaceholder {
  /* Placeholder border */
  border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 2px;
  display: flex;
  justify-content: center;

  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.avatarPlaceholderTextMobile {
  @media (--viewportMedium) {
    display: none;
  }
}
.avatarPlaceholderText {
  display: none;

  @media (--viewportMedium) {
    display: block;
    max-width: 130px;
    text-align: center;
  }
}

.avatarUploadError {
  /* Placeholder border */
  border-style: dashed;
  border-color: var(--failColor);
  border-width: 2px;
}

.error {
  /* Font */
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  margin-top: 18px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 22px;
    margin-bottom: 2px;
  }
}

.avatar {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 1px solid #ccc;
  object-fit: cover;
}

.changeAvatarContainer {
  flex: 1;
  /* text-align: right; */
  padding: 20px;
}

.changeAvatar {
  /* Font */
  background: #007373;
  -webkit-box-shadow: 0px 0px 22px -6px rgba(194, 150, 93, 1);
  -moz-box-shadow: 0px 0px 22px -6px rgba(194, 150, 93, 1);
  box-shadow: 0px 0px 22px -6px rgba(194, 150, 93, 1);
  color: #fff;
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px;

  @apply --marketplaceH5FontStyles;

  font-weight: var(--fontWeightMedium);

  @media (--viewportMedium) {
    /* Position: under */
    bottom: -10px;
    right: auto;
    margin-top: 0;
    margin-bottom: 0;
    transition: var(--transitionStyleButton);
    padding: 12px 40px;
    display: inline-block;
  }

  &:hover {
    border: solid 1px var(--matterColorAnti);
  }
}

.uploadingImage {
  /* Dimensions */
  width: var(--ProfileSettingsForm_avatarSize);
  height: var(--ProfileSettingsForm_avatarSize);

  /* Image fitted to container */
  object-fit: cover;
  background-color: var(--matterColorNegative); /* Loading BG color */
  border-radius: calc(var(--ProfileSettingsForm_avatarSize) / 2);
  overflow: hidden;

  display: block;
  position: relative;
  margin: 0;

  @media (--viewportMedium) {
    width: var(--ProfileSettingsForm_avatarSizeDesktop);
    height: var(--ProfileSettingsForm_avatarSizeDesktop);
    border-radius: calc(var(--ProfileSettingsForm_avatarSizeDesktop) / 2);
  }
}

.uploadingImageOverlay {
  /* Cover everything (overlay) */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  /* Overlay style */
  background-color: var(--matterColorLight);
  opacity: 0.8;

  /* Center content */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Avatar has square aspect ratio */
/* Default is 3:2 */
.squareAspectRatio {
  padding-bottom: 100%;
}

.avatarInvisible {
  visibility: hidden;
  position: absolute;
  top: -1000px;
  left: -1000px;
}

.tip {
  @apply --marketplaceDefaultFontStyles;
  color: var(--matterColorAnti);
  margin-top: 0;
  margin-bottom: 12px;
  padding-top: 5px;
  padding-bottom: 1px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.fileInfo {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 5px;
  padding-bottom: 1px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 6px;
    padding-bottom: 2px;
  }
}

.nameContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}

.bioInfo {
  color: var(--matterColorAnti);
  margin-top: 11px;

  @media (--viewportMedium) {
    margin-top: 16px;
  }
}

.submitButton {
  display: inline-block;
  margin-top: 24px;
  background: #007373;
  -webkit-box-shadow: 0px 0px 22px -6px rgba(194, 150, 93, 1);
  -moz-box-shadow: 0px 0px 22px -6px rgba(194, 150, 93, 1);
  box-shadow: 0px 0px 22px -6px rgba(194, 150, 93, 1);
  color: #fff;
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px;
  width: 100%;
  padding: 8px 30px;
}
.submitButton:disabled {
  background-color: #007373;
  color: #ffffff;
  cursor: not-allowed;
  box-shadow: none;
}

.locationSearchBox {
  position: relative;
  margin-bottom: 20px;
}
.locationIcon {
  position: absolute;
  left: 5px;
  top: 15px;
  margin: auto 0;
  padding: 0 10px;
  font-size: 20px;
  color: rgb(167, 167, 167);
}
.locationSearch input {
  border: 1px solid rgb(165, 165, 165);
  border-radius: 5px;
  overflow: hidden;
  background-color: white;
  font-family: 'Roboto', sans-serif;

  padding: 15px 15px 15px 45px;
  width: 100%;
  box-sizing: border-box;
  /* border-bottom-color: var(--attentionColor); */
}
.locationMap {
  border: 1px solid rgb(165, 165, 165);
  height: 300px;
  border-radius: 5px;
  overflow: hidden;
}
.top_visibility_message {
  margin-top: 50px;
  margin-bottom: 10px;
}
:global(.react-geocoder-results) {
  position: absolute;
  top: 100%;
  z-index: 2;
}
:global(.react-geocoder-item) {
  padding: 5px 15px;
  background: #eeeeee;
  color: #000;
  cursor: pointer;
}
:global(.react-geocoder-item:hover) {
  text-decoration: underline;
}

.fieldTip {
  display: none;
}

@media only screen and (max-width: 600px) {
  .form_field {
    width: 100%;
  }

  .root {
    padding: 20px;
  }

  .changeAvatar {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fieldTip {
    display: none;
  }

  .form_field_right {
    width: 100%;
  }

  .top_visibility_message {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 1200px) {
  .form_field {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  /* mobile */

  .avatarContainer {
    flex-direction: column;
  }

  .changeAvatarContainer {
    width: 100%;
  }

  .form_field_right {
    width: 100%;
  }

  .top_visibility_message {
    margin-bottom: 10px;
  }
}

.tag {
  background: #01cc01;
  display: inline-block;
  /* height: 50px; */
  width: 50px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 38px;
  padding: 0px 14px;
  margin: 20px 0 0px 20px;
  /* border-radius: 9px; */
  font-weight: 600;
  border-radius: 0px 5px 5px 0px;
  color: #058a0b;
  position: relative;
  z-index: 1;
}

div.tag:before {
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  position: absolute;
  left: -13px;
  top: 7px;
  /* border-radius: 9px; */
  transform: rotate(45.1deg);
  background: #01cc01;
  z-index: -1;
}

@import '../../marketplace.css';

.root {
  position: relative;
  margin-bottom: 20px;
}

.input {
  border: 1px solid rgb(165, 165, 165);
  border-radius: 5px;
  overflow: hidden;
  background-color: white;
  font-family: 'Roboto', sans-serif;

  padding: 15px 15px 15px 45px;
  width: 100%;
  box-sizing: border-box;
  /* border-bottom-color: var(--attentionColor); */
}

.inputSuccess {
  /* border-bottom-color: var(--successColor); */
}

.inputError {
  border-bottom-color: var(--failColor);
}

.textarea {
}

.input::placeholder {
  font-style: italic;
}

.icon {
  margin: auto 0;
  padding: 0 10px;
  font-size: 20px;
  color: rgb(167, 167, 167);
  position: absolute;
  left: 5px;
  top: 15px;
}

.base {
  position: relative;
  max-width: 700px;
  color: white;
  font-family: 'Roboto', sans-serif;
  overflow: hidden;
  /*box-shadow: 4px 14px 28px -9px rgba(168, 168, 168, 0.75);*/
}
.pictureContainer {
  position: relative;
  line-height: 0;
  overflow: hidden;
  border-radius: 10px;
}
.pictureContainer::after {
  content: ' ';
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1;
  -webkit-box-shadow: inset -136px -250px 126px -75px rgba(255, 255, 255, 1);
  -moz-box-shadow: inset -136px -250px 126px -75px rgba(255, 255, 255, 1);
  box-shadow: inset -136px -250px 126px -75px rgba(255, 255, 255, 1);
}
.pictureContainer img {
  width: 100%;
  object-fit: cover;
}
.profilePictureControlls {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 2;
  display: flex;
  text-align: center;
  justify-content: space-evenly;
  justify-content: center;
}
.profilePictureButton {
  margin: 10px 10px;
}
.profileLeftContainer {
}
.profileBadges {
  padding: 40px;
  text-align: center;
}
.profileRating {
  display: flex;
  color: #404040;
  padding: 10px 0;
}
.profileRating label {
  width: 60%;
  text-align: left;
}

@media only screen and (max-width: 768px) {
  .profileBadges {
    display: none;
  }
  .profilePictureControlls {
    padding: 15px 20px;
  }
  .profilePictureControlls div {
    margin: 0;
    min-width: 10px;
    flex: 1;
    border-radius: 0;
  }
  .profilePictureControlls div:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .profilePictureControlls div:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .profilePictureControlls a {
    padding: 15px 10px;
  }
}

@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --LandingPage_sectionMarginTop: 40px;
  --LandingPage_sectionMarginTopMedium: 60px;
  --LandingPage_sectionMarginTopLarge: 94px;
}


/* The emerging W3C standard
   that is currently Firefox-only */
   * {
    scrollbar-width: thin;
    scrollbar-color: #007373 white;
  }

/* Works on Chrome/Edge/Safari */
/**::-webkit-scrollbar {*/
/*  width: 8px;*/
/*  height: 8px;*/
/*}*/

/*!* Track *!*/
/**::-webkit-scrollbar-track {*/
/*  -webkit-box-shadow: inset 0 0 6px #007373;*/
/*  !* -webkit-border-radius: 10px; *!*/
/*  !* border-radius: 10px; *!*/
/*}*/

/*!* Handle *!*/
/**::-webkit-scrollbar-thumb {*/
/*  !* -webkit-border-radius: 10px; *!*/
/*  !* border-radius: 10px; *!*/
/*  background: rgba(180,130,62,0.5);*/
/*  -webkit-box-shadow: inset 0 0 6px rgba(180,130,62,0.7);*/
/*}*/
/**::-webkit-scrollbar-thumb:window-inactive {*/
/*  background: rgba(180,130,62,0.7);*/
/*}*/

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
  background-color: #007373;
}


.root {
}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  height: 67.5vh;
  max-height: 600px;
  padding: 0;

  @media (--viewportMedium) {
    min-height: 500px;
    height: 70vh;
    max-height: none;
  }

  @media (--viewportLarge) {
    max-height: 800px;
    min-height: 600px;
    height: calc(70vh - var(--topbarHeightDesktop));
  }
}

.hero {
  flex-grow: 1;
  justify-content: flex-end;
  padding-bottom: 32px;

  @media (--viewportMedium) {
    padding-bottom: 83px;
  }

  @media (--viewportLarge) {
    justify-content: center;
    padding-top: 60px;
  }
}

.sections {
  margin: 0;
  padding-top: 1px;
}

.section {
  overflow: auto;
}

.vcoach_img {
  width: 100%;
  margin-bottom: -4px;
  /* content: url("./web-banner_new.gif"); */
}

/* Square corners for the last section if it's even */
.section:nth-of-type(2n):last-of-type {
  @media (--viewportMedium) {
    border-radius: 4px 4px 0 0;
  }
}

/* Every other section has a light background */
.section:nth-of-type(2n) {
  background-color: var(--matterColorLight);
  @media (--viewportMedium) {
    border-radius: 4px;
  }
}

.sectionContent {
  margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;

  @media (--viewportMedium) {
    max-width: 100%;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px 0 36px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 93px auto;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.sectionContentFirstChild {
  composes: sectionContent;
  margin-top: 3vh;
}

/* A bar on top of light sections */
.section:nth-of-type(2n) .sectionContent::before {
  background: var(--marketplaceColor);
  content: '';
  display: block;
  width: 109px;
  height: 6px;

  /* Place the bar on top of .sectionContent top margin */
  position: relative;
  top: calc(-1 * var(--LandingPage_sectionMarginTop));

  @media (--viewportMedium) {
    width: 192px;
    height: 8px;
    top: calc(-1 * var(--LandingPage_sectionMarginTopMedium));
  }

  @media (--viewportLarge) {
    top: calc(-1 * var(--LandingPage_sectionMarginTopLarge));
  }
}

.landing_content{
  padding: 0 70px 50px;
}

.modalText > p:nth-child(3){
  cursor: pointer;
}

.modalText{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  font-size: 20px;
  flex-direction: column;
}

.page_title{
  color: #686464;
  font-family: 'Acumin Pro', serif;
  margin: 30px;
  max-width: 750px;
  font-size: 65px;
  font-weight: 300;
}

 .modalText > p:nth-child(1){
  font-size: 25px;
}

.modalText > p{
  margin: 10px;
}

.vcoach_banner_video{
  width: 100%;
  height: unset;
}

.mission_vision{
  font-weight: 300;
  font-size: 20px;
  color: #ababab;
  margin: -20px 0 0 0;
  padding: 0;
}

.button{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #007373;
  height: 63px;
  width: 265px;
  border-radius: 8px;
  color: #ffffff;
  transition: all 300ms ease-out;
  cursor: pointer;
}

.button:hover{
  opacity: 0.9;
}

.cta_with_pic {
  height: 500px;
  background-size: cover;
  background-position: center;
  position: relative;
  margin-top: 20px;
}


.cta_with_pic:hover .overlay{
  opacity: 0.3;
}

.overlay{
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAH0lEQVQYV2NkQAX/GZH4/xkYGBhhAmAOSBJEwDkgAQCCrgQEjpMcPgAAAABJRU5ErkJggg==') repeat;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  z-index: 5;
  transition: all 300ms ease-in-out;
}


.cta_background_img {
  z-index: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  filter: brightness(0.8);
  object-position: 0 -125px;
}

.cta_content {
  z-index: 5;
  position: absolute;
  height: 100%;
  width: 100%;
  padding-left: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.searchBox{
  display: flex;
  align-items: center;
  justify-content: center;
}

.features_block{
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 30px;
  margin-top: 30px;
}

.feature_block > img{
  width: 100%;
  height: 600px;
  object-fit: cover;
  border-radius: 10px;
}

.feature_block > p{
  font-weight: 400;
  font-size: 25px;
  color: #686464;
  margin-top: 15px;
  transition: all 300ms ease-out;
}

.feature_block{
  display: flex;
  flex-direction: column;
}

.feature_block:hover > img{
  filter: saturate(0.6);
}

.services_1_wrapper{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.services_1_wrapper > div{

}

.search_box{
  display: none;
  max-width: 500px;
  margin: 20px auto;
  box-shadow: 1px 1px 18px 0px #d2d2d2;
}

.partnerWrapper{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}

.joinVivekaWrapper{
  display: flex;
  /*grid-template-columns: 1fr 1fr;*/
  grid-gap: 20px;
  justify-content: space-between;
}

@media only screen and (max-width : 768px)  {
  .mission_vision{
    font-size: 20px;
  }

  .features_block{
    grid-template-columns: auto;
  }

  .page_title{
    font-size: 30px;
  }
}


@media only screen and (min-width : 768px) and (max-width : 1024px) {
  .vcoach_img {
    content: url("./web-banner_800_153.gif");
  }
}

@media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : portrait) {
  .landing_content{
    padding: 0 30px 50px;
  }
}

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen
  and (min-width: 320px)
  and (max-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .vcoach_img {
      content: url("./web-banner_400_77.gif");
    }
}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */
/* Portrait and Landscape */
@media only screen
  and (min-width: 375px)
  and (max-width: 667px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .vcoach_img {
      content: url("./web-banner_400_77.gif");
    }
}

@media only screen and (max-width : 768px){
  .base{}
  .landing_content{
    padding: 0 25px 30px;
  }

  .modalText > p:nth-child(1){
    font-size: 15px;
  }

  .modalText > a > p:nth-child(1){
    font-size: 15px;
  }

  .skylight-dialog > h2 {
    font-size: 18px;
    text-align: center;
  }

  .services_1_wrapper{
    grid-template-columns: 1fr
  }
}
@media only screen and (min-width : 375px) and (max-width : 812px) and (-webkit-device-pixel-ratio : 3){
  .landing_content{
    padding: 0 25px 30px;
  }

  .vcoach_img {
    content: url("./web-banner_480_92.gif");
  }
}
@media only screen and (min-width : 375px) and (max-width : 667px) {
  .modalText > p:nth-child(1){
    font-size: 15px;
  }

  .modalText > a > p:nth-child(1){
    font-size: 15px;
  }
}

@media only screen and (max-device-width: 730px) {
  .search_box{
    display: block;
  }
}


@media only screen and (max-device-width: 1000px) {
  .partnerWrapper{
    display: grid;
    grid-template-columns: 1fr;
  }

  .joinVivekaWrapper{
    display: grid;
    grid-template-columns: 1fr;
  }

}


.wrapper{
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.space {
  margin: 1.2vw;
}

.service_container{
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.service_item{
  flex: 50% 1;
  padding: 15px;
}

@media only screen and (min-width : 768px) and (max-width : 1024px) {
  .service_item{padding: 15px 15px 0;}
  .service_item:first-child{padding-top: 0;}
}
@media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : landscape) {

}

@media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : portrait) {
  .service_container{
    flex-direction: column;
  }
}

@media only screen and (max-width : 768px){
  .service_container{
    flex-direction: column;
  }
  .service_item{padding: 15px 15px 0;}
}
@media only screen and (min-width : 375px) and (max-width : 812px) and (-webkit-device-pixel-ratio : 3){
  .wrapper{
    flex-direction: column;
  }
}

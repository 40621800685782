.base {
  display: grid;
  grid-template-columns: 250px 5fr;
  font-family: 'Roboto', sans-serif;
}
@media screen and (max-width: 768px) {
  .base {
    grid-template-columns: 1fr;
  }
}

.logo {
  width: 130px;
  margin: 20px;
}

.menu_base {
  display: flex;
  flex-direction: column;
  box-shadow: 5px 0 15px 0 #00000014;
  z-index: 1;
  transition: all 300ms ease-out;
  height: 100vh;
}
@media screen and (max-width: 768px) {
  .menu_base {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 250px;
    background-color: white;
    margin-left: -250px;
  }
}

.section_wrapper {
  overflow-y: scroll;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .menu_open {
    margin-left: 0;
  }
}

.menu_nav_top {
  display: flex;
  justify-content: center;
  height: 100px;
}
.menu_nav_top img {
  filter: grayscale(100%);
}

.menu_close_button {
  display: none;
}
@media screen and (max-width: 768px) {
  .menu_close_button {
    display: flex;
    width: 30px;
    height: 30px;
    margin: 20px;
    color: #c4c4c4;
    align-self: flex-end;
  }
}

.menu_section {
  padding: 20px 40px;
}

.menu_item_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  cursor: pointer;
}
.menu_item_wrapper > p {
  font-weight: bold;
  font-size: 20px;
  margin: 0;
}
.menu_item_wrapper > svg {
  color: #989898;
}

.menu_item_active {
  color: #007373 !important;
}

.collapse_item_wrapper {
  display: flex;
  align-items: center;
  color: #989898;
  cursor: pointer;
  margin: 20px 0;
  text-decoration: none;
}
.collapse_item_wrapper > svg {
  margin: 0 20px;
}
.collapse_item_wrapper > p {
  font-weight: bold;
  margin: 0;
}

.nav_bar {
  box-shadow: 13px 6px 15px 0 #00000014;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav_bar > svg {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin: 30px 20px;
}
@media screen and (max-width: 768px) {
  .nav_bar > svg {
    opacity: 1;
    width: 30px;
    height: 30px;
    color: #989898;
  }
}

.nav_title {
  margin-left: 30px;
  font-size: 30px;
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  .nav_title {
    margin-left: 70px;
    font-size: 25px;
  }
}
@media screen and (max-width: 480px) {
  .nav_title {
    font-size: 18px;
  }
}

.nav_actions {
  display: flex;
  align-items: center;
}

.nav_message_button{
  cursor: pointer;
  margin-right: 10px;
}

.avatar_wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 20px;
  cursor: pointer;
}

.avatar_wrapper > div {
  color: #007373;
}

.profile_image {
  height: 40px;
  width: 40px;
  border-radius: 999px;
}

.content_base {
  height: 100vh;
  display: grid;
  grid-template-rows: 90px auto;
}

.content {
  overflow: scroll;
  padding: 20px;
}

.section_wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.section_wrapper::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
}

.section_wrapper::-webkit-scrollbar-thumb {
  background-color: #007373;
}

.content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.content::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
}

.content::-webkit-scrollbar-thumb {
  background-color: #007373;
}

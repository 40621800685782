.aiwrapper {
  font-family: 'Roboto', sans-serif;
}

.slider {
  box-shadow: 4px 14px 28px -9px rgba(168, 168, 168, 0.75);
  border-radius: 10px;
  /*overflow: hidden;*/
}

.aiwrapper > p {
  font-size: 18px;
  margin: 20px;
}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) {
  .aiwrapper > p {
    font-size: 13px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
  .aiwrapper > p {
    font-size: 13px;
  }
}

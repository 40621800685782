.base {
  position: relative;
  border-radius: 10px;
  width: 100%;
  max-width: 700px;
  color: white;
  font-family: 'Roboto', sans-serif;
  overflow: hidden;
  box-shadow: 4px 14px 28px -9px rgba(168, 168, 168, 0.75);
  background-size: cover;
  background-position: center;
}

.base img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  opacity: 0.2;
  z-index: 0;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAIklEQVQYV2NkQAIVFRX/GWF8EKejo4MRLADjgNiMyByQAAC3jQ5s74QFygAAAABJRU5ErkJggg==')
    repeat;
  background-color: #007373;
  opacity: 0.7;
}

.dark {
  background-color: black;
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAIklEQVQYV2NkQAIVFRX/GWF8EKejo4MRLADjgNiMyByQAAC3jQ5s74QFygAAAABJRU5ErkJggg==')
    repeat;
  background-color: #007373c2;
  z-index: 1;
  padding: 25px;
}
.dark .content {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAIklEQVQYV2NkQAIVFRX/GWF8EKejo4MRLADjgNiMyByQAAC3jQ5s74QFygAAAABJRU5ErkJggg==')
    repeat;
  background-color: rgba(80, 80, 80, 0.88);
}

.title {
  font-size: 30px;
  margin: 0;
}
.description {
  font-size: 15px;
  margin: 0 0 15px;
  min-height: 70px;
}

.button {
  align-self: center;
  border: white solid 2px;
}

@media only screen and (max-width: 768px) {
  .title {
    font-size: 24px;
  }
  .description {
    font-size: 14px;
  }
}

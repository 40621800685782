.wrapper{
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.space {
  margin: 1.2vw;
}
.expert_slide{
  display: flex;
  flex-wrap: wrap;
}
/*.expert_container{
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
}*/

@media only screen and (max-width: 930px) {
  .base{
    padding: 0 30px;
  }

  .base h1{
    font-size: 25px;
  }
}


@media only screen and (max-width: 900px) {
  .cards{
    flex-direction: column;
  }

  .cards > div{
    min-width: 100%;
    margin: 20px 0 0 0 !important;
  }

  .cards > div:nth-child(2){
    margin-bottom: 20px !important
  }
}

@media only screen and (max-width: 750px) {
  .explain > p:nth-child(1){
    font-size: 20px !important;
  }

  .explain > p:nth-child(2){
    font-size: 14px;
  }

}

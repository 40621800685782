.wrapper{
  display: flex;
  flex-direction: column;
}

.add{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 20px 10px 20px 0;
}

.tabSelected{
  background-color: transparent;
  border-bottom: 6px solid #007373 !important;
  color: #007373 !important;
}

.tab{
  border: none;
  color: rgb(53, 53, 53);
  font-size: 20px;
  margin-right: 30px;
  font-weight: 600;
  user-select: none;
}

.tabDisabled{
  color: rgb(160, 160, 160);
  cursor: default;
}

.tablist{
  border: none;
  padding:0;
  overflow-x: scroll;
  white-space: nowrap;
  height: 60px;
}

.line{
  width: 100%;
  height: 2px;
  background-color: rgba(151, 151, 151, 0.507);
  margin-top: 10px;
}

.tablist::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.tablist::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
}

.tablist::-webkit-scrollbar-thumb {
  background-color: #007373;
}

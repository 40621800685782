.partnerwrapper {
  font-family: 'Roboto', sans-serif;
  margin-top: 20px;
}
.slider {
  box-shadow: 4px 14px 28px -9px rgba(168, 168, 168, 0.75);
  border-radius: 10px;
  /*overflow: hidden;*/
}

.book_now {
  margin: 10px 0 0;
  width: 28%;
}
.flex {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 930px) {
  .flex {
    flex-direction: column;
  }
  .book_now {
    width: 100%;
  }
}

.partnerwrapper > p {
  font-size: 18px;
  margin: 20px;
  text-align: center;
}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) {
  .partnerwrapper > p {
    font-size: 13px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
  .partnerwrapper > p {
    font-size: 13px;
  }
  .book_now div {
    height: 35px;
  }
  .book_now a {
    padding: 0;
    font-size: 14px;
  }
  .book_now {
    width: 100%;
  }
}

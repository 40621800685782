.base {
  width: 100%;
  /*height: 60px;*/
  padding: 10px;
  margin-bottom: 5px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  overflow: hidden;
  transition: all ease-in-out 300ms;
  font-size: 20px;
  background-color: #007373;
}

.closeIcon {
  cursor: pointer;
}

.link{
  color: #7ed5ff;
  font-size: 18px;
}

.closed{
  /*height: 0;*/
  /*margin-bottom: 0;*/
  display: none;
}

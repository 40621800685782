:global(body){
  background: #007373;
}
.wrapper{
  margin: 0 auto;
  padding: 15px 40px;
}
.wrapper::after{
  clear: both;
  content: ' ';
  display: block;
}
@media only screen and (max-device-width : 768px){
  .wrapper{padding: 15px;}
}

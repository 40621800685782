/*@import url('https://fonts.googleapis.com/css?family=Playfair+Display|Roboto:100,200,300,400,500,600,700&display=swap');*/

body {
  margin: 0;
  padding: 0;
  background-color: #ffffff !important;
  font-family: 'Roboto', sans-serif;
}
blockquote {
  border-left: 5px solid #ccc;
  padding-left: 10px;
}
#root {
  max-width: 1440px;
  margin: 0 auto;
}
:global(div) {
  box-sizing: border-box;
}

/* react-tab */
:global(.react-tabs) {
  -webkit-tap-highlight-color: transparent;
}

:global(.react-tabs__tab-list) {
  border-bottom: 1px solid #aaa;
  margin: 0 0 10px;
  padding: 0;
}

:global(.react-tabs__tab) {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
}

:global(.react-tabs__tab--selected) {
  background: #fff;
  border-color: #aaa;
  color: black;
  border-radius: 5px 5px 0 0;
}

:global(.react-tabs__tab--disabled) {
  color: GrayText;
  cursor: default;
}

:global(.react-tabs__tab:focus) {
  box-shadow: 0 0 5px hsl(208, 99%, 50%);
  border-color: hsl(208, 99%, 50%);
  outline: none;
}

:global(.react-tabs__tab:focus:after) {
  content: '';
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
}

:global(.react-tabs__tab-panel) {
  display: none;
}

:global(.react-tabs__tab-panel--selected) {
  display: block;
}

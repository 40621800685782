.base {
  display: flex;
  flex-direction: column;
  max-width: 350px;
  height: fit-content;
  box-shadow: 20px 20px 40px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding-bottom: 20px;
  overflow: hidden;
}

.image {
  height: 150px;
  width: 350px;
  position: relative;
}

.image > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.price {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: white;
  padding: 5px 10px;
  border-radius: 4px;
}

.content {
  padding: 10px;
}

.title{
  font-weight: bold;
}

.description{
  color: #7c7c7c;
}

.actions {
  display: flex;
  padding: 0 10px;
  justify-content: space-between;
  color: #646464;
}

.actions > div {
  display: flex;
  gap: 15px;
  cursor: pointer;
}

.line {
  border-bottom: 1px solid #d0d0d0;
  /* margin: 20px 0 10px 0; */
  margin-bottom: 20px;
}

.base {
  display: flex;
  width: fit-content;
  font-family: 'Roboto', sans-serif;
  color: rgb(53, 53, 53);
  margin: 0 auto;
}

.pages {
  display: flex;
}

.pages div {
  width: 40px;
  height: 40px;
  margin: 0 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.selected {
  color: #007373;
  box-shadow: 4px 14px 28px -9px rgba(99, 99, 99, 0.75);
}

.changePage {
  height: 40px;
  margin: 0 10px;
  padding: 0 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page_prev::after {
  content: '<< Prev';
  display: inline-block;
}
.page_next::after {
  content: 'Next >>';
  display: inline-block;
}

.disabled {
  color: rgb(144, 144, 144);
  cursor: default;
}
@media only screen and (max-width: 768px) {
  .page_prev::after {
    content: '<<';
  }
  .page_next::after {
    content: '>>';
  }
  .pages div {
    margin: 0;
  }
  .changePage {
    margin: 0;
    padding: 0 5px;
  }
}

@import '../../marketplace.css';

.content {
  @media (--viewportMedium) {
    margin: 32px auto 0 auto;
    width: 100%;
    max-width: 564px;
  }

  @media (--viewportLarge) {
    margin: 0;
  }
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;
}

.title {
  hyphens: auto;
  margin-top: 8px;
  margin-bottom: 19px;

  @media (--viewportMedium) {
    margin-bottom: 47px;
  }
}

.pageRoot {
  display: flex;
  padding-bottom: 90px;
  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.loadingText {
  margin: 24px;
}

.add {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 20px 10px 20px 0;
}

.tabSelected {
  background-color: transparent;
  border-bottom: 6px solid #007373 !important;
  color: #007373 !important;
}

.tab {
  border: none;
  color: rgb(53, 53, 53);
  font-size: 20px;
  margin-right: 30px;
  font-weight: 600;
  user-select: none;
}

.tablist {
  border: none;
  padding: 0;
  overflow: auto;
  white-space: nowrap;
}

.line {
  width: 100%;
  height: 2px;
  margin-top: 10px;
  background-color: rgba(151, 151, 151, 0.507);
}

.outerbase {
  /* border-radius: 10px;
  width: 90%;
  font-family: 'Roboto', sans-serif;
  color: rgb(73, 73, 73);
  box-shadow: 4px 14px 28px 4px rgba(168, 168, 168, 0.75);
  margin-bottom: 100px;
  height: 850px; */
  padding: 0px 20px 20px 20px;
}

div.MuiStep-root.MuiStep-horizontal {
  background-color: red !important;
}

.base {
  display: flex;
  flex-direction: row;
  width: 100%;
  /* height: 250px; */
  font-family: 'Roboto', sans-serif;
  color: rgb(61, 61, 61);
  padding: 50px 0 50px 0;
}

.base > div > div {
  max-width: 100%;
}

.base > div {
  /* margin-right: 20px; */
}

.topbase {
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
}

.topleft {
  width: 50%;
  padding-left: 10%;
}

.topright {
  width: 30%;
  /* padding-right: 20%; */
}

.leftDivDes {
  width: 30%;
}

.rightDivDes {
  width: 70%;
  /* padding: 2vw 30px 2vw 0px; */
  display: flex;
  align-items: flex-end;
}

.leftDiv > div {
  margin-right: 20px;
}

.rightDiv {
  /* width: 45%; */
  /* padding: 2vw 30px 2vw 0px; */
}

.middleDiv {
  width: 10%;
  /* padding: 2vw; */
  width: 5%;
  border-left: 1px solid rgba(151, 151, 151, 0.507);
  height: 350px;
  margin-left: 50px;
}

.leftDiv p {
  font-size: 14px;
  /* padding-left: 10px; */
}

.rightDiv p {
  font-size: 14px;
  /* padding-left: 10px; */
}

.leftDiv p:nth-child(1) {
  color: black;
  margin: 0;
  font-size: 35px;
  font-weight: 350;
}

.rightDiv p:nth-child(1) {
  color: black;
  margin: 0;
  font-size: 35px;
  font-weight: 350;
}

.leftDivDes p:nth-child(1) {
  color: black;
  margin: 0;
  font-size: 35px;
  font-weight: 350;
}

.leftDivDes p:nth-child(2) {
  color: #007373;
  margin: 0;
  font-size: 35px;
  font-weight: 700;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: fit-content;
}

.start {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding-left: 30%; */
}

.start div:nth-child(2) {
  padding-top: 15px;
}

.row {
  display: flex;
  flex-direction: row;
}

.priceTag div:nth-child(1) {
  width: auto;
}

.row p:nth-child(1) {
  padding: 30px 30px 0px 30px;
}

.row p:nth-child(3) {
  padding: 30px 30px 0px 30px;
}

.classic {
  width: 275px;
  height: 350px;
}

.premium {
  width: 275px;
  height: 350px;
}

.wrapper {
  /* padding: 20px; */
}

.buttonsdiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 50px;
}

.buttonsPaymentDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 30%;
}

.button {
  background-color: #007373;
  width: 250px;
  height: 50px;
  /* margin: 0 auto; */
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  box-shadow: 4px 14px 28px -9px rgba(168, 168, 168, 0.75);
}

.icon {
  font-size: 26px;
  margin-left: 20px;
}

.titleRow {
  flex-direction: column;
}

.leftDivDes {
  width: 100%;
}

.rightDivDes {
  width: 100%;
}

.specialOffer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.specialOffer > img {
  width: 300px;
}

.specialOffer > p {
  font-size: 30px;
  font-weight: 500;
  color: #007373;
}

.confirmModel {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 520px;
}

.confirmModel > p:not(.confirmTitle) {
  margin: 10px;
  font-size: 20px;
}

.confirm {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
}

.confirmButton {
  cursor: pointer;
  margin: 10px;
  background-color: #007373;
  min-width: 70px;
  padding: 10px;
  text-align: center;
  color: white;
  border-radius: 4px;
}

.confirmTitle {
  font-size: 30px;
  color: rgb(42, 42, 42);
  margin: 10px;
}

.question {
  color: rgb(42, 42, 42);
  margin-right: 20px;
}

.subscriptionDetails {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 10px;
  box-shadow: 4px 14px 28px -9px rgba(168, 168, 168, 0.75);
  padding: 20px;
  margin-bottom: 50px;
}

.subscriptionTitle {
  font-size: 20px;
  font-weight: 400;
}

.subscriptionRemoveButton {
  height: 100%;
  width: 120px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  color: #ea3b3bad;
  border: 2px solid #007373db;
  box-shadow: 4px 14px 28px -9px rgba(168, 168, 168, 0.75);
  cursor: pointer;
  transition: all ease-in-out 300ms;
  margin-left: 20px;
}

.subscriptionRemoveButton:hover {
  color: red;
  border: 2px solid #007373;
}

.subscriptionAction {
  display: flex;
  align-items: center;
}

.hintIcon {
  color: #5d5d5d;
  font-size: 20px;
  margin-left: 10px;
}

.tooltip {
  background: #333333 !important;
  color: #a1a1a1 !important;
}

@media only screen and (max-width: 600px) {
  .outerbase > div:nth-child(1) {
    flex-direction: column;
  }

  .rightDivDes {
    width: 100%;
  }

  .rightDivDes > p {
    padding: 0 !important;
  }

  .leftDivDes {
    width: 100%;
  }
  .leftDiv,
  .rightDiv {
    width: 100%;
  }

  .base {
    justify-content: space-between;
    flex-direction: column;
  }

  .buttonsPaymentDiv {
    justify-content: flex-end;
  }

  .stripeConfig {
    width: 100%;
  }

  .planCheck > p {
    padding: 30px 5px 0 5px !important;
  }

  .priceTag > div {
    width: auto;
    padding: 0 10px 0 0;
  }
}

@media only screen and (max-width: 1200px) {
  .titleRow {
    flex-direction: column;
  }

  .leftDivDes {
    width: 100%;
  }

  .rightDivDes {
    width: 100%;
  }

  .base {
    justify-content: space-between;
    flex-direction: column;
    padding: 20px 0 50px 0;
  }

  .base > div {
    width: 100%;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 750px) {
  /* .leftDivDes p:nth-child(1) {
    font-size: 25px;
  } */

  .base {
    padding: 0px 0 50px 0;
  }

  .leftDivDes p:nth-child(1) {
    font-size: 20px;
  }

  .leftDivDes p:nth-child(2) {
    font-size: 25px;
  }

  .leftDiv p:nth-child(1) {
    font-size: 25px;
  }
}

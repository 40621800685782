.base {
  display: flex;
  margin: 10px 100px;
}

.title {
  color: rgb(47, 47, 47);
  font-size: 30px;
  font-weight: 700;
  margin: 0;
}

.listName {
  color: #007373;
  font-size: 30px;
  font-weight: 700;
  margin: 0;
  cursor: pointer;
}

.mDate{
  font-size: 15px;
  font-weight: 600;
  margin: 10px 0 0 0;
}

.textDescription{
  font-size: 15px;
  font-weight: 500;
  margin: 10px 0 0 0;
}

.chat {
  width: 70%;
}

.rightSection {
  width: 30%;
  padding: 20px;
}

.messageInput {
    display: flex;
    margin: 20px 0 5px 0;
  }

.messageBubbles{
  display: flex;
  flex-direction: column-reverse;
  max-height: 600px;
  overflow: auto;
}

.sendButton {
  width: 100px;
  height: 40px;
  background-color: #007373;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 0 28px -9px rgba(168, 168, 168, 0.75);
}

.sendButton > .icon {
  color: white;
}

.mInput {
  border-radius: 2px;
  width: 700px;
  margin-right: 5px;
  border: 0 solid rgb(80, 80, 80);
  font-size: 20px;
}

.mInput:focus {
  outline: none;
}

.line {
  width: 100%;
  height: 2px;
  background-color: #007373;
  margin-bottom: 10px;
  border-bottom: 20px;
}

.sendSpinner{
  stroke: white;
}

.mBack{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  /* color: #007373; */
  margin-bottom: 20px;
}

.mBack .icon{
  font-size: 30px;
  margin-right: 10px;
}

.pendingButtons{
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 50px;
}

.pendingButtons > p{
  box-shadow: 4px 14px 28px -9px rgba(168, 168, 168, 0.75);
  padding: 10px;
  border-radius: 4px;
  width: 90px;
  text-align: center;
  margin: 5px;
  color: white;
  cursor: pointer;
  transition: all 200ms ease-out;
}

.pendingButtons > p:nth-child(1){
  background-color: rgb(0, 190, 0);
}

.pendingButtons > p:nth-child(2){
  background-color: rgb(190, 44, 0);
}

.pendingButtons > p:hover{
  filter: brightness(85%);
}

.bookNowButton{
  padding: 10px;
  background-color: #007373;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  color: white;
}

@media only screen and (max-width: 600px) {
  .base{
    flex-direction: column;
    margin: 10px 10px;
  }

  .chat{
    width: 100%;
    padding: 20px;
  }

  .rightSection{
    width: 100%;
  }

  .sendButton{
    width: 20%;
  }

  .mInput{
    width: 80%;
  }
}

@media only screen and (max-width: 1024px) {
  .base{
    flex-direction: column;
  }

  .chat{
    width: 100%;
  }

  .rightSection{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (max-width: 768px) {
  .base{
    margin: 10px 50px;
    }
 }

.base {
  position: relative;
  color: #404040;
  font-family: 'Roboto', sans-serif;
  overflow: hidden;
  font-size: 16px;
}
.bio_title {
}
.bio_title:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}
.bio_title h1 {
  font-size: 36px;
  color: #404040;
  font-weight: bold;
  margin: 0;
  display: inline-block;
  vertical-align: middle;
}
.base p {
  margin: 0;
}
.job {
  font-weight: bold;
}
.location {
  font-weight: 300;
}
.ratingContainer {
  margin-top: 20px;
  margin-bottom: 30px;
}
.ratingContainer::after {
  display: block;
  content: ' ';
  clear: both;
}
.ratingBlock {
  display: inline-block;
  float: left;
  line-height: 36px;
}
.ratingLabel {
  font-size: 16px;
  font-weight: bold;
  line-height: 36px;
  margin-left: 15px;
}
.ratingLabel span {
  font-weight: 300;
}
.bio {
  font-size: 15px;
  line-height: 28px;
  white-space: pre-line;
}
.profileContactContainer {
  margin: 30px 0;
}
.profileContact {
  float: left;
  margin-right: 30px;
  font-weight: 600;
}
.socialIcons {
  padding-top: 3px;
}
.socialIcons a {
  padding: 0 2px;
}
.socialIcons svg {
  color: #007373;
  margin: 10px;
  font-size: 30px;
  cursor: pointer;
}
.profileBadges {
  display: block;
}
@media only screen and (max-device-width: 768px) {
  .profileBadges {
    display: block;
    padding: 15px 0;
  }
  .bio_title {
    display: flex;
    align-items: center;
  }
  .bio_title h1 {
    flex: 1;
  }
  .profileContactContainer {
    text-align: center;
  }
  .ratingContainer {
    margin-bottom: 10px;
  }
  .profileContact {
    float: none;
    margin: 0 30px;
  }
  .socialIcons {
    padding-top: 15px;
  }
  .socialIcons * {
    font-size: 28px;
  }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) {
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
}

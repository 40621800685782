.base{
  margin: 0 auto;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  font-weight: 600;
}

.heading{
  font-size: 30px;
  margin: 0;
}

.row{
  display: flex;
  justify-content: space-between;
}

.incDec{
  display: flex;
  align-items: center;
}

.incDec > div{
  background-color: rgb(173, 173, 173);
  color: white;
  width: 25px;
  height: 45px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 4px 14px 28px -9px rgba(168, 168, 168, 0.75);
}

.incDec > p{
  margin: 0 17px;
}

.button{
  background-color: #007373;
  width: 250px;
  height: 50px;
  margin: 0 auto;
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  box-shadow: 4px 14px 28px -9px rgba(168, 168, 168, 0.75);
}

.icon{
  font-size: 26px;
  margin-left: 20px;
}

.card_outer {
  width: 25%;
  flex: 25%;
  padding: 0 15px;
}
.card {
  font-family: 'Roboto', sans-serif;
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.16);
}
.card a {
  text-decoration: none;
  color: #333;
}
.imageContainer {
  max-width: 500px;
}
.image {
  position: relative;
  background-color: rgb(240, 240, 240);
  padding-top: 100%;
}

.img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 2px;
  object-position: top;
}

.available {
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
  color: white;
  background-color: black;
  height: 20px;
  width: 90px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  margin: 10px;
}

.available div {
  background-color: #007373;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  margin-right: 5px;
}

.content {
  display: block;
  padding: 15px 0 0;
}
.check_packages {
  color: #5d5d5d;
  font-style: italic;
  text-decoration: underline;
  font-size: 14px;
  margin: 15px 0 10px;
}
.name {
  font-size: 18px;
  margin: 10px 0 0;
}
.title {
  font-size: 18px;
  margin: 10px 0 0;
}
.location {
  font-size: 16px;
  color: rgb(100, 100, 100);
  margin: 0px 0 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.book_now {
  margin: 10px 0 0;
  color: #fff;
}
.book_now a {
  color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
}

@media only screen and (max-width: 768px) {
  .card_outer {
    padding: 8px 8px;
    width: 50%;
    flex: 50% 1;
  }
  .card {
    padding: 10px;
    -webkit-box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.16);
  }
  .book_now div {
    height: 35px;
  }
  .book_now a {
    padding: 0;
    font-size: 14px;
  }

  .name {
    font-size: 14px;
  }
  .location {
    font-size: 12px;
  }
  .title {
    font-size: 12px;
  }
}

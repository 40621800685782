.base img.icon_20{
  margin-right: 10px;
  height: 20px;
}
.base img.icon_40{
  margin-right: 20px;
  height: 40px;
}
.base img.icon_60{
  margin-right: 30px;
  height: 60px;
}

.base.icon_20 img{
  margin-right: 10px;
  height: 20px;
}
.base.icon_40 img{
  margin-right: 20px;
  height: 40px;
}
.base.icon_60 img{
  margin-right: 30px;
  height: 60px;
}


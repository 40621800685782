.base {
}

.tabs {
  display: flex;
  overflow: auto;
  white-space: nowrap;
}

.tabs > p {
  font-weight: 600;
  font-size: 20px;
  color: #353535;
  margin-right: 30px;
  padding: 6px 12px;
  cursor: pointer;
}

.selected {
  border-bottom: 6px solid #007373;
  color: #007373 !important;
}

.messageBase {
  display: flex;
  margin-top: 15px;
  cursor: pointer;
}

.messageDescHighlight {
  border: #ff6666 3px solid;
}

.image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}

.image > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.messageDesc {
  display: flex;
  flex-direction: column;
  padding: 25px;
  box-shadow: 0 0 28px -9px rgba(168, 168, 168, 0.75);
  border-radius: 5px;
  width: 100%;
}

.messageDesc p {
  margin: 0;
}

.firstRow {
  display: flex;
  justify-content: space-between;
}

.time {
  align-self: flex-end;
}

.name {
  font-size: 18px;
  font-weight: 600;
}

.accepted {
  color: #37e219;
  font-weight: 600;
}

.declined {
  color: rgb(223, 20, 20);
  font-weight: 600;
}

.complete {
  color: rgb(20, 111, 223);
  font-weight: 600;
}

.pending {
  color: #e2b217;
  font-weight: 600;
}

.enquire {
  font-weight: 600;
}

.selectOptions {
  height: 40px;
  width: 150px;
  border-radius: 5px;
  font-family: 'Roboto', sans-serif;
  font-size: 17px;
  border: 1px solid rgb(177, 177, 177);
}

.pagination {
  width: 100%;
  display: flex;
}

.pagination > p {
  background-color: #e2b217;
  height: 40px;
  width: 40px;
  margin-right: 10px;
  border-radius: 6px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 100ms ease-in-out;
}

.pagination > p:hover {
  filter: brightness(80%);
}

.currentPageDetails {
  display: flex;
}

.currentPageDetails > p {
  margin-right: 10px;
}

@media only screen and (max-width: 1060px) {
  .messageDesc {
    min-width: 80%;
  }
}

@media only screen and (max-width: 930px) {
  .messageDesc {
    min-width: 90%;
  }

  .pagination {
    justify-content: center;
  }
}

@media only screen and (max-width: 600px) {
  .messageDesc {
    min-width: 100%;
  }

  .messageBase {
    flex-direction: column;
    align-items: center;
    margin: 0 20px;
  }

  .image {
    margin: 30px 0 10px 0;
  }

  .selectOptions {
    margin: 0 20px;
    width: 90%;
  }
}

.other_profiles{
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}
.other_profiles::after{
  clear: both;
  content: ' ';
  display: block;
}
.other_profile_item{
  flex: 50%;
  max-width: 50%;
  padding: 0 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

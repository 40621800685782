.wrapper {
  font-family: 'Roboto', sans-serif;
  margin-top: 50px;
}

.heading {
  color: rgb(59, 59, 59);
  font-weight: 600;
  font-size: 25px;
}

.wrap {
  width: 100%;
  justify-content: space-between;
}
.top_service_slide {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.top_service_item {
  width: 33.33333%;
  flex: 33.33333% 1;
  box-sizing: border-box;
  padding: 9px;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .top_service_item {
    width: 50%;
    flex: 50% 1;
  }
}

@media only screen and (min-width: 375px) and (max-width: 812px) and (-webkit-device-pixel-ratio: 3) {
}
@media only screen and (min-width: 375px) and (max-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
  .top_service_item {
    width: 100%;
    flex: 100% 1;
  }
}

@media only screen and (min-width: 768px) and (min-width: 889px) {
  .top_service_item {
    width: 50%;
    flex: 50% 1;
  }
}

@media only screen and (max-width: 768px) {
  .heading {
    font-size: 18px;
  }
  .wrapper {
    margin-top: 0;
  }
  .top_service_item {
    width: 100%;
    flex: 100% 1;
  }
}

:global(body){
  background: #007373;
}
.wrapper{
  margin: 0 auto;
  max-width: 1700px;
}
.wrapper::after{
  clear: both;
  content: ' ';
  display: block;
}

.wrapper {
  width: 100%;
  min-height: 500px;
  font-family: 'Roboto', sans-serif;
  /*color: rgb(143, 143, 143);*/
  display: flex;
  flex-direction: column;
  color: #454545;
}

.header {
  padding-left: 40px;
  font-size: 37px;
  font-weight: 500;
  margin-bottom: 10px;
  color: rgb(80, 80, 80);
  position: relative;
  width: fit-content;
}

.notificationCount {
  color: red;
  position: absolute;
  bottom: -10px;
  right: 0;
  font-size: 15px;
}

.notificationView {
  text-decoration: none;
  color: #333333;
  font-style: italic;
}

.line {
  width: 100%;
  height: 2px;
  background-color: #007373;
}

.base {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* margin-bottom: 50px; */
}

.left {
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.05);
  margin-top: 10px;
  display: flex;
  overflow-x: scroll;
}

.flexrow {
  display: flex;
  flex-direction: row;
  padding-left: 30px;
  cursor: pointer;
}

.left div {
  display: flex;
  align-items: center;
  height: 60px;
  margin-bottom: 20px;
  padding-left: 30px;
  width: 100%;
  cursor: pointer;
  position: relative;
}

.left div p {
  margin: 10px;
  font-weight: 500;
  font-size: 18px;
}
/*rgba(214, 173, 113)*/
.selected {
  border-width: 2px;
  border-bottom: 5px solid #007a87;
}

.selected > p {
  color: #007373;
}

/*.selected::after{*/
/*  content: '';*/
/*  position: absolute;*/
/*  top: 0;*/
/*  right: 0;*/
/*  height: 60px;*/
/*  width: 5px;*/
/*  background-color: #007373;*/
/*}*/

.icon {
  color: #454545;
  font-size: 20px;
  margin: 13px;
}

.selectedIcon {
  color: #007373;
}

.iconFaQuestionCircle {
  color: rgb(195, 195, 195);
  font-size: 20px;
  margin: 13px;
}

.notification {
  color: red !important;
}

.right {
  /*background-color: #f6f8f9;*/
  overflow: hidden;
  min-height: 500px;
  padding-bottom: 20px;
}

.slideDown {
  display: none;
}

.tooltip {
  background: #333333 !important;
  color: #a1a1a1 !important;
}

.nonSelected:hover {
  /*color: rgba(214, 173, 113, 1);*/
  color: #007373;
}

.nonSelected:hover .icon {
  color: #007373;
}

.customNotification {
  background-color: #ffa726;
  min-height: 40px;
  width: 75%;
  align-self: flex-end;
  display: flex;
  align-items: center;
  color: white;
  position: relative;
}

.notificationMessage {
  font-size: 15px;
}

.notificationIcon {
  color: white;
  font-size: 25px;
  margin: 10px;
}

.customNotification > p {
  margin: 0;
  padding: 0;
}

.notificationAction {
  position: absolute;
  right: 30px;
  cursor: pointer;
}

.closeButton {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 15px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .base {
    flex-direction: column;
  }

  .header {
    text-align: center;
    padding: 0;
  }

  .left {
    width: 100%;
  }

  .right {
    width: 100%;
  }

  .selected {
    width: 100% !important;
  }

  .wrapper {
    padding: 0;
    align-items: center;
  }

  .left {
    /*height: 0;*/
    /*overflow: hidden;*/
  }

  .slideDown {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 50px;
    background-color: #007373;
    cursor: pointer;
  }

  .slideDown > span {
    height: 2px;
    width: 45px;
    background-color: rgb(255, 255, 255);
    margin-bottom: 3px;
  }

  /* .slideDown:active + .left{
    height: 600px !important;
  } */

  .sliderOpen {
    height: auto;
  }

  .customNotification {
    width: 100%;
  }
}

@media only screen and (max-width: 1000px) {
  .left div {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  /* mobile */
  .header {
    font-size: 25px;
  }

  .notificationCount {
    bottom: 30px;
  }

  .customNotification {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: landscape) {
}

@media only screen and (min-width: 1024px) and (max-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
}

@media only screen and (min-width: 1024px) and (max-width: 1366px) {
  .right {
    min-height: 690px;
  }
}

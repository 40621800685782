.wrapper {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  color: rgb(88, 88, 88)
}

.price div input {
  border: 1px solid #cacaca;
  border-radius: 9px;
  min-height: 65px;
  width: 220px;
  font-size: 20px;
  margin-right: 20px;
}

.price div select {
  border: 1px solid #cacaca;
  border-radius: 9px;
  min-height: 65px;
  width: 220px;
  font-size: 20px;
  background-color: #f4f4f4;
}

.checkBox {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

/*.checkBox div {*/
/*  border: 1px solid #cacaca;*/
/*  min-width: 190px;*/
/*  min-height: 50px;*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  margin-right: 70px;*/
/*  border-radius: 9px;*/
/*  cursor: pointer;*/
/*}*/

.selected{
  background-color: #007373;
  color: white;
  border: 1px solid #007373 !important;
  box-shadow: 4px 14px 28px -9px rgba(168, 168, 168, 0.75);
}

.typeOfSearvice{
  width: 465px;
}

.next{
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  margin: 25px;
  margin-right: 0;
}

.error{
  color: rgb(255 91 91);
  font-size: 20px;
  padding: 20px;
  background-color: #f1f1f11a;
  border-radius: 10px;
  width: -moz-fit-content;
  width: fit-content;
  box-shadow: 1px 1px 15px 2px #e6e6e6;
  font-style: italic;
}

.next p{
  margin-right: 20px;
  font-size: 26px;
  color: rgb(66, 66, 66)
}

.link{
  color: #007373;
}

.button{
  height: 45px;
  width: 140px;
  background-color: #007373;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  margin-top: 20px;
  align-self: flex-end;
}

.select_dot{
  height: 40px;
  width: 40px;
  border-radius: 999px;
  background-color: whitesmoke;
  border: 1px solid black;
}

.selectedCategory{
  background-color: #007373;
  border: 1px solid #007373 !important;
  color: white;
  box-shadow: 4px 14px 28px -9px rgba(168, 168, 168, 0.75);
}

@media only screen and (max-width: 768px) {
  .wrapper {
    padding: 20px;
  }

  .checkBox {
    flex-direction: column;
  }

  /*.checkBox > div{*/
  /*  margin-top: 20px;*/
  /*  margin-right: 0;*/
  /*}*/

  .typeOfSearvice{
    width: 100%;
  }

  .price > div > input{
    width: 100%;
  }

  .price > div > select{
    margin-top: 20px;
    width: 100%;
  }
}

.base{
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-bottom: 30px;
  color: #686464;
  font-family: 'Acumin Pro', serif
}

.small{
  font-weight: 600;
  font-size: 42px;
  color: #686464;
  z-index: 50;
}

.big{

  font-size: 60px;
  /*color: #007373;*/
  margin-top: -25px;
  margin-left: 25px;
}

@media only screen and (min-width : 768px) and (max-width : 1024px) {

}
@media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : landscape) {

}

@media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : portrait) {
  .big{
    font-size: 52px;
    margin-top: -28px;
    margin-left: 20px;
  }
}

@media only screen and (max-width : 768px){
  .base{margin-top: 0; margin-bottom: 20px;}
  .small{font-size: 24px;}
  .big{
    font-size: 28px;
    margin-top: -15px;
    margin-left: 25px;
  }
}
@media only screen and (min-width : 375px) and (max-width : 812px) and (-webkit-device-pixel-ratio : 3){
  .small{font-size: 17px;}
  .big{
    font-size: 20px;
    margin-top: -8px;
    margin-left: 14px;
  }
}

.base {
  font-family: 'Roboto', sans-serif;
  color: #686464;
  font-size: 16px;
  /*background: #333;*/
  padding: 0 70px;
  position: relative;
  z-index: 101;
}
.base a {
  width: fit-content;
  color: #686464;
  font-size: 16px;
  display: block;
  text-decoration: none;
}
.landing_content {
  display: flex;
}
.landing_content > p {
  padding-left: 10px;
  width: 100%;
  text-align: center;
}
.leftDiv {
  width: 40%;
  padding: 2vw 2vw 2vw 0;
}

.logo {
  max-width: 100%;
}

.logo img {
  height: 100%;
  filter: grayscale(100%);
  width: 200px;
}

.leftDiv p {
  padding-left: 10px;
  margin-bottom: 30px;
}

.middleDiv {
  width: 30%;
  padding: 2vw;
}

.middleDiv p {
  padding: 0;
  margin: 0;
}

.rightDiv {
  width: 30%;
  padding: 2vw 10px 2vw 2vw;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.rightDiv h3 {
  align-self: flex-start;
}

.email {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}

.searchDiv {
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  border: #5d5d5d solid 1.5px;
  width: 100%;
  height: 40px;
  background-color: #5d5d5d;
}

.searchDiv input {
  border: 0;
  width: 80%;
  padding-left: 2vw;
}

.searchDiv input::placeholder {
  font-family: 'Roboto', sans-serif;
}

.searchButton {
  width: 50px;
  height: 100%;
  background-color: #5d5d5d;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
}

.socialIcons {
  display: flex;
  margin-top: 20px;
}

.socialIcons > a {
  margin: 0;
}

.socialIcons * {
  color: #007373;
  margin: 5px;
  font-size: 40px;
  cursor: pointer;
}

.paymentIcons * {
  color: #8d8d8d;
  font-size: 35px;
  padding: 0 5px;
}

.icons {
  margin: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
}

@media only screen and (max-width: 768px) {
  .base {
    padding: 10px 50px 10px;
    text-align: center;
  }
  .landing_content {
    flex-direction: column;
  }
  .leftDiv {
    width: 100%;
  }
  .leftDiv p {
    display: none;
  }
  .middleDiv {
    width: 100%;
    text-align: center;
  }
  .middleDiv a {
    display: inline-block;
    margin: 5px;
  }
  .rightDiv {
    width: 100%;
    align-items: center;
  }
  .socialIcons {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .socialIcons * {
    font-size: 30px;
  }

  .middleDiv h3 {
    display: none;
  }

  .rightDiv h3 {
    display: block;
    text-align: center;
    text-align: center;
    align-self: center;
  }
}

@import '../../marketplace.css';

.desktopReviewsTitle {
  @apply --marketplaceH3FontStyles;
  margin-bottom: 0;
}

.page_wrapper {
  max-width: 1440px;
  background: #fff;
}

.desktopReviewsTabNav {
  margin-bottom: 32px;
}
.profile_container {
  display: flex;
  position: relative;
}
.profileLeft {
  width: 45%;
}
.profileRight {
  width: 55%;
  right: 0;
  top: 0;
}
.profile_right_inner {
  padding: 30px 80px 30px 40px;
}
.other_profiles {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}
.other_profiles::after {
  clear: both;
  content: ' ';
  display: block;
}
.other_profile_item {
  flex: 50%;
  max-width: 50%;
  padding: 0 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
}
.profile_left_padded {
  padding: 0 80px;
}
.reviews_container {
  padding: 50px 0;
}
.profile_badge_stack {
  margin-bottom: 50px;
}
.profile_services {
  margin-bottom: 50px;
}

.profile_languages {
  margin-bottom: 50px;
}
.profile_accreditation {
  margin-bottom: 50px;
  align-items: center;
  font-size: 16px;
  color: #404040;
  font-family: 'Roboto', sans-serif;
}
.profile_left_section {
  margin-bottom: 50px;
}
.profile_assets {
  margin-top: 20px;
}

@media only screen and (max-width: 768px) {
  .profile_container {
    flex-direction: column;
  }
  .profileLeft {
    width: 100%;
  }
  .profileRight {
    width: 100%;
    position: relative;
  }

  .profile_left_padded {
    padding: 0 80px;
  }
  .profile_right_inner {
    padding: 15px 20px;
  }
  .reviews_container {
    padding: 10px 20px;
  }
  .profile_badge_stack {
    padding: 0 20px 10px;
  }
  .profile_left_section {
    padding: 0 20px;
  }
  .profile_accreditation {
    padding: 0 20px;
  }
}

@media only screen and (min-width: 375px) and (max-width: 812px) and (-webkit-device-pixel-ratio: 3) {
}
@media only screen and (min-width: 375px) and (max-width: 667px) {
}

.demo_form{
  display: flex;
  flex-direction: column;
  padding: 20px;
  color: #1a1a1a;
}

.field{
  height: 50px;
  border-radius: 9px;
  padding-left: 20px;
  font-size: 20px;
}

.button{
  height: 50px;
  width: 130px;
  background-color: #d6ad71;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 9px;
  margin-top: 20px;
  align-self: flex-end;
  cursor: pointer;
}


.form_base{
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
}

.close_button{
  display: flex;
  justify-content: center;
  align-content: center;
  position: absolute;
  top: 0px;
  right: 0px;
  color: red;
  cursor: pointer;
  font-size: 25px;
  z-index: 999999;
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.list {
  box-shadow: 4px 14px 28px -9px rgba(168, 168, 168, 0.75);
  border-radius: 10px;
  min-height: 200px;
  padding: 2vw;
}

.pagination {
  padding: 30px;
}

.noListings {
  width: 100%;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  color: rgb(110, 110, 110);
}

.spinner {
  align-self: center;
  height: 55px;
  width: 55px;
}

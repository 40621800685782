.base {
  position: relative;
  border-radius: 10px;
  width: 100%;
  max-width: 700px;
  min-height: 475px;
  color: white;
  font-family: 'Roboto', sans-serif;
  overflow: hidden;
  box-shadow: 4px 14px 28px -9px rgba(168, 168, 168, 0.75);
  background-size: cover;
  background-position: center;
  margin: 0 10px;
}

.base img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  opacity: 0.2;
  z-index: 0;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #007373;
  opacity: 0.7;
}

.dark {
  background-color: black;
}

.content {
  color: #404040;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
  /* background-color: rgba(175, 154, 126, 0.9); */
  z-index: 1;
  padding: 25px;
}

.dark .content {
  background-color: rgba(80, 80, 80, 0.88);
}

.title {
  font-size: 30px;
  text-align: center;
  font-weight: 600;
  margin: 0px;
}

.description {
  font-size: 15px;
  text-align: justify;
  padding: 0 15px 0 15px;
  min-height: 20%;
}

.subpoints {
  font-size: 15px;
  padding-left: 20px;
}

.button {
  align-self: center;
  /* border: white solid 2px; */
  width: 90%;
  margin-top: 10px;
  margin-bottom: 10px;
  position: absolute;
  bottom: 10px;
}

.subpointsDispaly {
  display: flex;
}

.checkIcon {
  color: #007373;
  font-size: 1.5em;
}

.subpoints > svg {
  padding-right: 20px;
}

@media only screen and (max-width: 768px) {
  .title {
    font-size: 24px;
  }
  .description {
    font-size: 14px;
  }
  .base {
    width: unset;
  }
}

@media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
  /* For portrait layouts only */
  .base {
    min-height: 460px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: landscape) {
  /* For landscape layouts only */
  .base {
    min-height: 510px;
  }
}

/* ----------- iPhone X ----------- */

/* Landscape */
@media only screen and (min-width: 375px) and (max-width: 812px) and (orientation: landscape) {
  .base {
    min-height: 400px;
  }
}

.wrapper {
  width: 100%;
  margin: 0;
  padding: 50px 50px 0;
  display: flex;
  flex-direction: column;
  padding: 30px 0 0;
}
.discover_container {
  /*display: flex;
  flex-wrap: wrap;
  padding:60px 0 0;
  margin-left: -15px;
  margin-right: -15px;
  justify-content: space-around;*/
  margin-top: 30px;
}
.discover_slide {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.title {
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  font-size: 30px;
  color: #404040;
  z-index: 50;
}
.heading {
  margin-bottom: 7px;
}

.slider {
}
:global(.slick-arrow) {
  position: absolute;
  top: -74px;
  right: 0;
  border: none;
  outline: 0;
  font-size: 0;
  line-height: 0;
  z-index: 100;

  padding: 24px 23px 24px 18px;
  background: transparent;
  min-height: 0;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.4);
}

:global(.slick-arrow:before) {
  content: ' ';
  display: block;
  border-style: solid;
  border-color: #000;
  border-width: 3px 3px 0 0;
  width: 12px;
  height: 12px;
  transform: translate(-50%, -50%) rotate(45deg);
}
:global(.slick-arrow.slick-prev) {
  left: auto;
  right: 50px;
  padding: 24px 18px 24px 23px;
}
:global(.slick-arrow.slick-prev:before) {
  transform: translate(-50%, -50%) rotate(-135deg);
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  :global(.slick-arrow) {
    top: -55px;
  }
}

@media only screen and (max-device-width: 768px) {
  .wrapper {
    padding: 0px 10px;
  }
  .discover_container {
    padding-top: 20px;
    margin-top: 0;
  }
  .title span:first-child {
    font-weight: 300;
    font-size: 27px;
    color: #404040;
    margin: 0;
    line-height: 30px;
  }
  .title .type_of_service {
    display: block;
    font-size: 24px;
    margin: 0;
    font-weight: 700;
    line-height: 24px;
  }
  :global(.BrainhubCarousel__customArrows) {
    top: 0 !important;
  }

  :global(.slick-arrow) {
    top: -45px;
    padding: 16px 18px 16px 12px;
  }
  :global(.slick-arrow.slick-prev) {
    padding: 16px 12px 16px 18px;
    right: 40px;
  }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) {
  :global(.BrainhubCarousel__customArrows) {
    top: 0 !important;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
  :global(.BrainhubCarousel__customArrows) {
    top: 0 !important;
  }
}

.table {
  width: 100%;
  border-radius: 9px;
  overflow: hidden;
  box-shadow: 4px 14px 28px -9px rgba(168, 168, 168, 0.75);

}

.table > tr > th {
  /*background-color: #007373;*/
  /*color: white;*/
  height: 50px;
}

.table > tr {
  height: 40px;
}

.table td, .table th{
  border-bottom: 1px solid rgba(88, 88, 88, 0.25);
}

.table > tr:last-child > td{
  border-bottom: 0;
}

.table td{
  border-right: 1px solid rgba(88, 88, 88, 0.25);
}

.table td:last-child{
  border-right: 0;
}

/*.table > tr:nth-child(even) {*/
/*  background-color: rgba(203, 203, 203, 0.49)*/
/*}*/

.table > tr > td:nth-child(1){
  padding-left: 10px;
}

.table > tr > td:nth-child(2), .table > tr > td:nth-child(3){
  text-align: center;
  color: rgb(54, 54, 54);
  font-weight: 500;
}

.planCol{
  width: 15%;
}

@media only screen and (max-width: 750px) {
  .table {
    font-size: 14px;
  }
}

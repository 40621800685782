@import '../../marketplace.css';

.root {
  margin-top: 24px;
}

@media (--viewportMedium) {
  .root {
    margin-top: 48px;
  }
}

.disabled {
  opacity: 0.5;
}

.sectionContainer {
  margin-bottom: 35px;

  @media (--viewportMedium) {
    margin-bottom: 56px;
  }
}

.sectionContainer div select {
  border: 1px solid rgb(165, 165, 165);
  border-radius: 5px;
  overflow: hidden;
  background-color: white;
  font-family: 'Roboto', sans-serif;
  padding: 15px 15px 15px 45px;
  width: 100%;
  box-sizing: border-box;
  border-bottom-color: #007373;
}

.formRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
}

.selectCountry {
  margin-bottom: 24px;
}

.form_field {
  padding: 0;
  margin-bottom: 20px;
  width: 100%;
  position: relative;
}

.form_field svg {
  margin: auto 0;
  padding: 0 10px;
  font-size: 20px;
  color: rgb(167, 167, 167);
  position: absolute;
  left: 5px;
  top: 35px;
}

.error {
  @apply --marketplaceModalErrorStyles;
}

.bankDetailsStripeField p {
  @apply --marketplaceH4FontStyles;
}

.missingStripeKey {
  color: var(--failColor);
}

.accountInformationTitle {
  @apply --marketplaceH3FontStyles;
  margin: 0;
  padding-top: 5px;
  padding-bottom: 1px;
}

@media (--viewportMedium) {
  .accountInformationTitle {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.savedCountry {
  padding-top: 6px;
  padding-bottom: 0px;
}
@media (--viewportMedium) {
  .savedCountry {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.savedInformation {
  margin-bottom: 24px;
}

.savedBankAccount {
  width: 100%;
  border-bottom: 2px solid var(--successColor);
  margin-bottom: 24px;
  padding: 4px 0 10px 0;
  color: var(--matterColor);
  text-align: left;

  &:hover {
    cursor: text;
    text-decoration: none;
    border-color: var(--matterColor);
  }

  &:focus {
    outline: none;
    border-color: var(--matterColor);
  }
}

.cards{
  display: flex;
  justify-content: space-between;
}

.space {
  margin: 1.2vw;
}

@media only screen and (min-width : 768px) and (max-width : 1024px) {

}
@media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : landscape) {

}

@media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : portrait) {

}

@media only screen and (max-width : 768px){
  .cards{
    flex-direction: column;
  }
}
@media only screen and (min-width : 375px) and (max-width : 812px) and (-webkit-device-pixel-ratio : 3){
  .cards{
    flex-direction: column;
  }
}
@media only screen and (min-width : 375px) and (max-width : 667px) {
}



